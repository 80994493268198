import React from 'react';

import digitalVitaminImage from '../../../game/assets/digital.png';
import creativeVitaminImage from '../../../game/assets/criat.png';
import offlineVitaminImage from '../../../game/assets/off.png';
import communicationVitaminImage from '../../../game/assets/comu.png';
import tecnologyVitaminImage from '../../../game/assets/tec.png';

import './prizes-screen.css';

const PrizesScreen = () => (
  <div id="prizes-screen">
    <div id="scrollable-text">
      <p className="title strong-yellow">PRÉMIOS</p>
      <br />
      <p>
        Aqui está o mais importante. Se aceitares o desafio de jogar o VIT-MAN, eis o que podes
        ganhar: <br />
      </p>
      <br />
      <ul className="list">
        <li>
          1hora em consultoria – <img className="prize-img" src={digitalVitaminImage} />{' '}
          <img className="prize-img" src={creativeVitaminImage} />
        </li>
        <li>
          5% desconto em Website – <img className="prize-img" src={digitalVitaminImage} />
        </li>
        <li>
          10% desconto em Lojas Online – <img className="prize-img" src={digitalVitaminImage} />
        </li>
        <li>
          5% desconto em Aplicação Mobile – <img className="prize-img" src={digitalVitaminImage} />
        </li>
        <li>
          10% desconto em Plano Estratégico de Marketing –{' '}
          <img className="prize-img" src={digitalVitaminImage} />{' '}
          <img className="prize-img" src={offlineVitaminImage} />
        </li>
        <li>
          5% desconto em vídeo institucional –{' '}
          <img className="prize-img" src={communicationVitaminImage} />
        </li>
        <li>
          5% desconto em soluções tecnológicas –{' '}
          <img className="prize-img" src={communicationVitaminImage} />
        </li>
        <li>
          1 esferográfica – <img className="prize-img" src={tecnologyVitaminImage} />
        </li>
        {/* <li>E-goi: Oferta de 5 Planos Pro de E-mail Marketing</li> */}
        <li>
          Alojamento PTisp Linux Inicial Plesk –{' '}
          <img className="prize-img" src={digitalVitaminImage} />
        </li>
        <li>
          Certificados PTisp Alpha SSL – <img className="prize-img" src={digitalVitaminImage} />
        </li>
      </ul>
      <br />
      <br />
      <p>
        <span className="strong-red">ATENÇÃO:</span>{' '}
        <span className="strong-yellow">
          a possibilidade de ganhar prémios nem sempre está ativa. E quando está ativa, é por um
          período de 2 semanas. Tens de estar atento ao teu e-mail ou às redes sociais para saberes
          quando o VIT-MAN tem novos prémios para tentares ganhar. No entretanto, podes simplesmente
          jogar pela diversão e passar um bom momento 😊
        </span>
      </p>
    </div>
  </div>
);

export default PrizesScreen;
