import React from 'react';
import PropTypes from 'prop-types';
import ArcadeItem from '../../ArcadeItem/ArcadeItem';
import { ARCADE_ITEMS_TYPE, COUNTDOWN_SCREEN, SCREENS } from '../../../common/constants';

import './countdown-screen.css';

const textSVGBaseProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const CountdownScreen = ({ days, hours, minutes, setSelectedScreen }) => (
  <div id="countdown-screen">
    <ArcadeItem type={ARCADE_ITEMS_TYPE.VITAMIN} />
    <ArcadeItem type={ARCADE_ITEMS_TYPE.YELLOW_GHOST} />
    <ArcadeItem type={ARCADE_ITEMS_TYPE.PACMAN} />
    <ArcadeItem type={ARCADE_ITEMS_TYPE.WHITE_GHOST} />
    <svg viewBox="0 0 1356.98 449.89">
      <text x="50%" y="20%" {...textSVGBaseProps} className="text white">
        {COUNTDOWN_SCREEN.IS_ONLINE}
      </text>
      <text x="50%" y="40%" {...textSVGBaseProps} className="text yellow">
        {days}
        {COUNTDOWN_SCREEN.DAYS} {hours}
        {COUNTDOWN_SCREEN.HOURS} {minutes}
        {COUNTDOWN_SCREEN.MINUTES}
      </text>
      <text
        x="50%"
        y="80%"
        {...textSVGBaseProps}
        className="btn text white"
        onClick={() => setSelectedScreen(SCREENS.PRE_REGISTER_SCREEN)}
      >
        <animate
          attributeType="XML"
          attributeName="fill"
          values="#fff;#ffa500;#fff"
          dur="1.5s"
          repeatCount="indefinite"
        />
        {COUNTDOWN_SCREEN.PRE_REGISTER}
      </text>
    </svg>
  </div>
);

CountdownScreen.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  setSelectedScreen: PropTypes.func.isRequired,
};

export default CountdownScreen;
