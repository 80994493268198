import matrix, { isWall, pathpoints } from './MazeUtils';

export const randomPoint = () => {
  const points = pathpoints();
  const randomPick = Math.floor(Math.random() * points.length + 1);
  return { y: points[randomPick - 1].y, x: points[randomPick - 1].x };
};

export const pointInRadius = (centerx, centery, radius) => {
  const points = pathpoints();
  const circlePoints = [];

  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const dx = Math.abs(point.x - centerx);
    const dy = Math.abs(point.y - centery);
    const dr = Math.sqrt(dx * dx + dy * dy);
    if (dr <= radius) {
      circlePoints.push(point);
    }
  }

  const randomPick = Math.floor(Math.random() * circlePoints.length + 1);
  return { y: circlePoints[randomPick - 1].y, x: circlePoints[randomPick - 1].x };
};

export const isPointInRadius = (centerx, centery, pointx, pointy, radius) => {
  const points = pathpoints();

  const isPathPoint = points.find((p) => p.x === pointx && p.y === pointy);

  if (!isPathPoint) return false;

  const dx = Math.abs(pointx - centerx);
  const dy = Math.abs(pointy - centery);
  const dr = Math.sqrt(dx * dx + dy * dy);
  if (dr <= radius) {
    return true;
  }
  return false;
};

export const shortPathToPoint = (rowPos, columnPos, finalRowPos, finalColumnPos) => {
  let rq = [];
  let cq = [];
  let move_count = 0;
  let nodes_left_in_layer = 1;
  let nodes_in_next_layer = 0;
  let reached_end = false;
  const numberOfRows = matrix.length;
  const numberOfColumns = matrix[0].length;

  let lastVisited = [];
  let visited = [];

  matrix.forEach((el) => {
    visited.push([...el]);
  });

  const dr = [-1, 1, 0, 0];
  const dc = [0, 0, 1, -1];

  function explore_neighbours(r, c) {
    for (let i = 0; i < 4; i++) {
      let rr = r + dr[i];
      let cc = c + dc[i];

      if (rr < 0 || cc < 0) {
        continue;
      }

      if (rr >= numberOfRows || cc >= numberOfColumns) {
        continue;
      }

      if (visited[rr][cc] === true) {
        continue;
      }

      if (isWall(matrix[rr][cc])) {
        continue;
      }

      rq.unshift(rr);
      cq.unshift(cc);

      visited[rr][cc] = true;

      lastVisited.push({
        last: [r, c],
        node: [rr, cc],
      });

      nodes_in_next_layer++;
    }
  }

  function solve() {
    rq.unshift(rowPos);
    cq.unshift(columnPos);

    visited[rowPos][columnPos] = true;

    lastVisited.push({
      last: undefined,
      node: [rowPos, columnPos],
    });

    while (rq.length > 0) {
      let r = rq.pop();
      let c = cq.pop();

      if (r === finalRowPos && c === finalColumnPos) {
        reached_end = true;
        break;
      }

      explore_neighbours(r, c);

      nodes_left_in_layer--;

      if (nodes_left_in_layer === 0) {
        nodes_left_in_layer = nodes_in_next_layer;
        nodes_in_next_layer = 0;
        move_count++;
      }
    }
    if (reached_end) {
      return move_count;
    }

    return -1;
  }

  function getPath() {
    let path = [];

    path.push(
      lastVisited.find((el) => el.node[0] === finalRowPos && el.node[1] === finalColumnPos)
    );

    if (!path[0]) {
      return undefined;
    }

    while (path[path.length - 1].last !== undefined) {
      path.push(
        lastVisited.find(
          (el) =>
            el.node[0] === path[path.length - 1].last[0] &&
            el.node[1] === path[path.length - 1].last[1]
        )
      );
    }

    path.pop();

    return path;
  }

  solve();
  return getPath();
};
