import { GAMEMODE } from './Game';
import { inputKeys } from './inputKeys';

const swithckeys = (event, game) => {
  event.preventDefault();
  switch (event.key) {
    case inputKeys.Left:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Left;
      }
      break;
    case inputKeys.H:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Left;
      }
      break;
    case inputKeys.Right:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Right;
      }
      break;
    case inputKeys.L:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Right;
      }
      break;
    case inputKeys.Up:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Up;
      }
      break;
    case inputKeys.K:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Up;
      }
      break;
    case inputKeys.Down:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Down;
      }
      break;
    case inputKeys.J:
      if (game.gamemode === GAMEMODE.RUNNING) {
        game.pacman.waitingDirection = inputKeys.Down;
      }
      break;
    case inputKeys.Space:
      if (game.gamemode === GAMEMODE.INTRO || game.gamemode === GAMEMODE.START) {
        game.gameModeReady();
      }
      break;
    default:
      break;
  }
};

export default class InputHandler {
  constructor(game) {
    this.encaps = (event) => swithckeys(event, game);

    document.addEventListener('keydown', this.encaps, true);
  }

  removeKeys() {
    document.removeEventListener('keydown', this.encaps, true);
  }
}
