import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { GAME_SCREEN_STATE } from '../../../../common/constants';

import './end-game.css';
import { EMAIL_TYPE } from '../../../../../server/app/config/egoi';

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://vitman.pt/';

const SEND_EMAIL = 'egoi/email/send';

const textSVGBaseProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

export const sendEmail = (firstName, lastName, email, emailType) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const body = {
    firstName,
    lastName,
    email,
    emailType,
  };

  fetch(`${BASE_URL}${SEND_EMAIL}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers,
  })
    .then((res) => {
      if (!res.ok) {
        throw { status: res.status, message: res.statusText };
      }
    })
    .then(() => {})
    .catch(() => {});
};

const EndGame = ({
  setGameScreenState,
  points,
  email,
  prize,
  firstName,
  lastName,
  previousPlayed,
}) => {
  useEffect(() => {
    let emailType;

    if (!prize || prize === '') {
      emailType = EMAIL_TYPE.LOSE_EMAIL;
    } else {
      emailType = EMAIL_TYPE.WIN_EMAIL;
    }

    sendEmail(firstName, lastName, email, emailType);
  }, []);

  return (
    <svg id="keys-picker" viewBox="0 0 1356.98 449.89">
      <text x="50%" y="10%" {...textSVGBaseProps} className="text yellow">
        O JOGO TERMINOU
      </text>
      <text x="50%" y="25%" {...textSVGBaseProps} className="text white">
        Pontuação: {points}
      </text>
      <text x="50%" y="45%" {...textSVGBaseProps} className="text warning">
        VERIFICA O TEU E-MAIL
      </text>
      {!previousPlayed && (
        <>
          <text x="50%" y="70%" {...textSVGBaseProps} className="text orange">
            QUERES GANHAR UMA VIDA EXTRA?
          </text>
          <text
            x="50%"
            y="85%"
            {...textSVGBaseProps}
            className="text orange click"
            onClick={() => setGameScreenState(GAME_SCREEN_STATE.SHARE_GAME)}
          >
            <animate
              attributeType="XML"
              attributeName="fill"
              values="#fff;#ffa500;#fff"
              dur="1.5s"
              repeatCount="indefinite"
            />
            -&gt; PARTILHA O JOGO COM UM AMIGO &lt;-
          </text>
        </>
      )}
      {previousPlayed && (
        <text x="50%" y="70%" {...textSVGBaseProps} className="text orange">
          OBRIGADO POR JOGARES!
        </text>
      )}
    </svg>
  );
};

EndGame.propTypes = {
  setGameScreenState: PropTypes.func,
  points: PropTypes.number,
  email: PropTypes.string,
  prize: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  previousPlayed: PropTypes.bool,
};

export default EndGame;
