export const inputKeys = {
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Up: 'ArrowUp',
  Down: 'ArrowDown',
  Space: ' ',
  R: 'r',
  P: 'p',
  H: 'h',
  J: 'j',
  K: 'k',
  L: 'l',
};
