class MazeObject {
  constructor(game, x, y) {
    this.game = game;
    this.x = x * game.unitSize;
    this.y = y * game.unitSize;
    this.defaultX = x * game.unitSize;
    this.defaultY = y * game.unitSize;
  }

  resetMazeObject() {
    this.x = this.defaultX;
    this.y = this.defaultY;
  }
}

export default MazeObject;
