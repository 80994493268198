import MovingSprite from './MovingSprite';
import Maze from './Maze';
import { inputKeys } from './inputKeys';
import { shortPathToPoint } from './algorithms';
import Sprite from './Sprite';
import {
  frightnedBlue,
  frightnedWhite,
  eyesDown,
  eyesUp,
  eyesLeft,
  eyesRight,
  vitamins,
} from './spriteSheetPoints';
import { randomPoint } from './algorithms';

const SpriteFrameSpeed = 10;
const ghostImage = document.getElementById('ghosts');
const frightnedVelocity = 1;
const eyesVelocity = 6;
const basePoint = { x: 9, y: 10 };

export const GHOST_MODE = {
  HOME: 'HOME',
  CHASE: 'CHASE',
  SCATTER: 'SCATTER',
  FRIGHTNED: 'FRIGHTNED',
  TRANSITION: 'TRANSITION',
  GOING_TO_BASE: 'GOING_TO_BASE',
};

const probability = function (n) {
  return !!n && Math.random() <= n;
};

const digitalV = document.getElementById('digital_sprite');

class Ghost extends MovingSprite {
  constructor(game, x, y, velocity, direction, sprites, scatterPoints) {
    super(game, x, y, velocity, direction, sprites);

    this.ghostmode = GHOST_MODE.CHASE;
    this.targetPoint = { x: x, y: y };
    this.lastTargetPoint = { x: 0, y: 0 };
    this.path = undefined;
    this.lastGhostSavedTime = 0;
    this.scatterPoints = scatterPoints;
    this.scatterPointIndex = 0;

    this.frightnedSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        frightnedBlue,
        frightnedBlue.length,
        SpriteFrameSpeed
      ),
    };

    this.transitionSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        frightnedWhite,
        frightnedWhite.length,
        SpriteFrameSpeed
      ),
    };

    this.eyesSprites = {
      [inputKeys.Right]: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        eyesRight,
        eyesRight.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Left]: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        eyesLeft,
        eyesLeft.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Up]: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        eyesUp,
        eyesUp.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Down]: new Sprite(
        game.unitSize,
        game.unitSize,
        ghostImage,
        eyesDown,
        eyesDown.length,
        SpriteFrameSpeed
      ),
    };

    this.digitalSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        digitalV,
        vitamins,
        vitamins.length,
        SpriteFrameSpeed
      ),
    };

    this.vitamin = undefined;
    this.freezePos = false;
  }

  resetGhost() {
    this.resetMovingSprite();
    this.ghostmode = GHOST_MODE.CHASE;
    this.targetPoint = { x: this.x / this.game.unitSize, y: this.y / this.game.unitSize };
    this.lastTargetPoint = { x: 0, y: 0 };
    this.path = undefined;
    this.lastGhostSavedTime = 0;
    this.scatterPointIndex = 0;
    this.freezePos = false;
  }

  ajustPosition() {
    switch (this.direction) {
      case inputKeys.Up:
        while (this.y % this.velocity !== 0) {
          this.y--;
        }

        break;
      case inputKeys.Down:
        while (this.y % this.velocity !== 0) {
          this.y++;
        }

        break;
      case inputKeys.Right:
        while (this.x % this.velocity !== 0) {
          this.x++;
        }

        break;
      case inputKeys.Left:
        while (this.x % this.velocity !== 0) {
          this.x--;
        }
        break;
      default:
        break;
    }
  }

  freeze() {
    this.freezePos = true;
    this.velocity = 0;
  }

  unfreeze() {
    this.freezePos = false;
    this.velocity = this.defaultVelocity;
  }

  isScared() {
    return this.ghostmode === GHOST_MODE.TRANSITION || this.ghostmode === GHOST_MODE.FRIGHTNED;
  }

  isGoingHome() {
    return this.ghostmode === GHOST_MODE.GOING_TO_BASE;
  }

  onTargetPoint(targetPointX, targetPointY) {
    const ghostMazePos = Maze.getMazePosition(this.game, this.x, this.y);

    return ghostMazePos.x === targetPointX && ghostMazePos.y === targetPointY;
  }

  chaseMode(progressTimeSeconds) {
    this.ghostmode = GHOST_MODE.CHASE;
    this.sprites = this.defaultSprites;
    this.vitamin = undefined;
    this.lastGhostSavedTime = progressTimeSeconds;
    this.velocity = this.defaultVelocity;
    this.ajustPosition();
  }

  scatterMode(progressTimeSeconds) {
    this.ghostmode = GHOST_MODE.SCATTER;
    this.lastGhostSavedTime = progressTimeSeconds;
    this.targetPoint = this.scatterPoints[this.scatterPointIndex];
  }

  frightnedMode(progressTimeSeconds) {
    this.ghostmode = GHOST_MODE.FRIGHTNED;

    if (probability(0.25)) {
      if (probability(0.7)) {
        this.sprites = this.digitalSprites;
        this.vitamin = this.sprites;
      } else {
        this.sprites = this.vitaminSprites;
        this.vitamin = this.sprites;
      }
    } else {
      this.sprites = this.frightnedSprites;
    }

    this.lastGhostSavedTime = progressTimeSeconds;
    this.velocity = frightnedVelocity;
    this.targetPoint = randomPoint();
    this.ajustPosition();
  }

  transitionMode(progressTimeSeconds) {
    this.ghostmode = GHOST_MODE.TRANSITION;
    this.sprites = this.transitionSprites;
    this.vitamin = undefined;
    this.lastGhostSavedTime = progressTimeSeconds;
  }

  goingToBaseMode(progressTimeSeconds) {
    this.ghostmode = GHOST_MODE.GOING_TO_BASE;
    this.sprites = this.eyesSprites;
    this.lastGhostSavedTime = progressTimeSeconds;
    this.targetPoint = basePoint;
    this.velocity = eyesVelocity;
    this.ajustPosition();
  }

  setDestination() {
    const ghostMazePos = Maze.getMazePosition(this.game, this.x, this.y);
    const targetMoved =
      this.lastTargetPoint.x !== this.targetPoint.x ||
      this.lastTargetPoint.y !== this.targetPoint.y;

    if (targetMoved) {
      this.lastTargetPoint.x = this.targetPoint.x;
      this.lastTargetPoint.y = this.targetPoint.y;
      this.path = shortPathToPoint(
        ghostMazePos.y,
        ghostMazePos.x,
        this.targetPoint.y,
        this.targetPoint.x
      );
    }

    if (this.path && this.path.length > 0) {
      const nextPos = this.path[this.path.length - 1].node;

      const dy = ghostMazePos.y - nextPos[0];
      const dx = ghostMazePos.x - nextPos[1];

      if (dy > 0) {
        this.waitingDirection = inputKeys.Up;
      }

      if (dy < 0) {
        this.waitingDirection = inputKeys.Down;
      }

      if (dx > 0) {
        this.waitingDirection = inputKeys.Left;
      }

      if (dx < 0) {
        this.waitingDirection = inputKeys.Right;
      }

      if (ghostMazePos.y === nextPos[0] && ghostMazePos.x === nextPos[1]) {
        this.path.pop();
      }

      if (
        this.ghostmode === GHOST_MODE.GOING_TO_BASE &&
        targetMoved &&
        !this.canChangeDirection()
      ) {
        if (this.direction === 'ArrowUp' && this.y < nextPos[0] * this.game.unitSize) {
          this.direction = 'ArrowDown';
        }

        if (this.direction === 'ArrowDown' && this.y > nextPos[0] * this.game.unitSize) {
          this.direction = 'ArrowUp';
        }

        if (this.direction === 'ArrowRight' && this.x < nextPos[1] * this.game.unitSize) {
          this.direction = 'ArrowLeft';
        }

        if (this.direction === 'ArrowLeft' && this.x > nextPos[1] * this.game.unitSize) {
          this.direction = 'ArrowRight';
        }
      }
    }
  }

  updateGhost(progress) {
    const progressTimeSeconds = Math.floor(progress / 1000);

    if (
      this.ghostmode === GHOST_MODE.CHASE &&
      progressTimeSeconds - this.lastGhostSavedTime === 30
    ) {
      this.scatterMode(progressTimeSeconds);
    }

    if (this.ghostmode === GHOST_MODE.SCATTER) {
      if (this.onTargetPoint(this.targetPoint.x, this.targetPoint.y)) {
        this.scatterPointIndex++;

        if (this.scatterPointIndex === this.scatterPoints.length) {
          this.scatterPointIndex = 0;
        }
      }

      if (progressTimeSeconds - this.lastGhostSavedTime === 20) {
        this.chaseMode(progressTimeSeconds);
      }

      this.targetPoint = this.scatterPoints[this.scatterPointIndex];
    }

    if (this.ghostmode === GHOST_MODE.FRIGHTNED || this.ghostmode === GHOST_MODE.TRANSITION) {
      if (this.onTargetPoint(this.targetPoint.x, this.targetPoint.y)) {
        this.targetPoint = randomPoint();
      }
    }

    if (
      this.ghostmode === GHOST_MODE.FRIGHTNED &&
      progressTimeSeconds - this.lastGhostSavedTime === 5
    ) {
      this.transitionMode(progressTimeSeconds);
    }

    if (
      this.ghostmode === GHOST_MODE.TRANSITION &&
      progressTimeSeconds - this.lastGhostSavedTime === 5
    ) {
      this.chaseMode(progressTimeSeconds);
    }

    if (
      this.ghostmode === GHOST_MODE.GOING_TO_BASE &&
      this.onTargetPoint(this.targetPoint.x, this.targetPoint.y)
    ) {
      this.chaseMode(progressTimeSeconds);
    }

    this.setDestination(this.targetPoint);

    this.updateSprite();
  }
}

export default Ghost;
