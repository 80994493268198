import MovingObject from './MovingObject';

class MovingSprite extends MovingObject {
  constructor(game, x, y, velocity, direction, sprites) {
    super(game, x, y, velocity, direction);
    this.sprites = sprites;
    this.defaultSprites = sprites;
  }

  resetMovingSprite() {
    this.resetMovingObject();
    this.sprites = this.defaultSprites;
  }

  updateSprite() {
    if (this.canChangeDirection()) {
      this.direction = this.waitingDirection;

      if (!this.sprites['static']) {
        this.sprites[this.direction].resetFrameIndex();
      }
    }

    if (this.sprites['static']) {
      this.sprites['static'].update();
    } else {
      this.sprites[this.direction].update();
    }

    this.move();
  }

  draw(context) {
    if (this.sprites['static']) {
      this.sprites['static'].draw(this.x, this.y + this.game.gameStartY, context);
    } else {
      this.sprites[this.direction].draw(this.x, this.y + this.game.gameStartY, context);
    }
  }
}

export default MovingSprite;
