import React from 'react';
import PropTypes from 'prop-types';

import { GAME_SCREEN, GAME_SCREEN_STATE } from '../../../../common/constants';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const LoginMessage = ({ gameScreenState, setGameScreenState }) => {
  switch (gameScreenState) {
    case GAME_SCREEN_STATE.LOGGING_IN:
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="50%" {...baseSVGTextProps} className="text yellow">
            <animate
              attributeType="XML"
              attributeName="fill"
              values="#fff;#ffa500;#fff"
              dur="1.5s"
              repeatCount="indefinite"
            />
            {GAME_SCREEN.LOGGING_IN}
          </text>
        </svg>
      );
    case 'SHARING':
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="50%" {...baseSVGTextProps} className="text yellow">
            <animate
              attributeType="XML"
              attributeName="fill"
              values="#fff;#ffa500;#fff"
              dur="1.5s"
              repeatCount="indefinite"
            />
            A ENVIAR
          </text>
        </svg>
      );
    case GAME_SCREEN_STATE.LOGIN_ERROR_BASE:
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="40%" {...baseSVGTextProps} className="text white">
            Ups. Algo correu mal.
          </text>
          <text
            onClick={() => setGameScreenState(GAME_SCREEN_STATE.LOGIN)}
            x="50%"
            y="65%"
            {...baseSVGTextProps}
            className="btn text yellow"
          >
            <animate
              attributeType="XML"
              attributeName="fill"
              values="#fff;#ffa500;#fff"
              dur="1.5s"
              repeatCount="indefinite"
            />
            Por favor tenta novamente!
          </text>
        </svg>
      );
    case GAME_SCREEN_STATE.LOGIN_ERROR_INVALID_LOGIN:
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="40%" {...baseSVGTextProps} className="text white">
            Login Inválido!
          </text>
          <text
            onClick={() => setGameScreenState(GAME_SCREEN_STATE.LOGIN)}
            x="50%"
            y="65%"
            {...baseSVGTextProps}
            className="btn text yellow"
          >
            <animate
              attributeType="XML"
              attributeName="fill"
              values="#fff;#ffa500;#fff"
              dur="1.5s"
              repeatCount="indefinite"
            />
            Por favor tenta novamente!
          </text>
        </svg>
      );
    case GAME_SCREEN_STATE.LOGIN_ERROR_NO_LIVES:
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="40%" {...baseSVGTextProps} className="text white">
            Ups. Parece que já esgostaste as vidas!
          </text>
          <text x="50%" y="65%" {...baseSVGTextProps} className="btn text yellow">
            Aguarda pela próxima fase de prémios!
          </text>
        </svg>
      );
    case 'SHARE_ERROR':
      return (
        <svg id="intro" viewBox="0 0 1356.98 449.89">
          <text x="50%" y="40%" {...baseSVGTextProps} className="text white">
            {GAME_SCREEN.LOGIN_ERROR.ERROR}
          </text>

          <text
            onClick={() => setGameScreenState(GAME_SCREEN_STATE.LOGIN)}
            x="50%"
            y="60%"
            {...baseSVGTextProps}
            className="btn text yellow"
          >
            A PARTILHA FALHOU
          </text>
        </svg>
      );

    default:
      return null;
  }
};

LoginMessage.propTypes = {
  gameScreenState: PropTypes.string.isRequired,
  setGameScreenState: PropTypes.func,
};

export default LoginMessage;
