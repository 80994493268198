export const boundingBoxDetection = (ax, ay, bx, by, aunitSize, bunitSize) => {
  const aX = ax + aunitSize - 1;
  const aY = ay + aunitSize - 1;
  const bX = bx + bunitSize - 1;
  const bY = by + bunitSize - 1;

  const cx = ax < bx ? bx : ax;
  const cy = ay < by ? by : ay;
  const cX = aX < bX ? aX : bX;
  const cY = aY < bY ? aY : bY;

  if (ax < bX && aX > bx && ay < bY && aY > by) {
    return { cx, cy, cX, cY };
  }

  return false;
};

export const pixelColision = (a, b, bbox, ctx) => {
  ctx.clearRect(bbox.cx, bbox.cy, bbox.cX - bbox.cx, bbox.cY - bbox.cy);
  a.draw(ctx);
  const ia = ctx.getImageData(bbox.cx, bbox.cy, bbox.cX - bbox.cx, bbox.cY - bbox.cy);

  ctx.clearRect(bbox.cx, bbox.cy, bbox.cX - bbox.cx, bbox.cY - bbox.cy);
  b.draw(ctx);
  const ib = ctx.getImageData(bbox.cx, bbox.cy, bbox.cX - bbox.cx, bbox.cY - bbox.cy);

  const resolution = 4 * 5;
  const length = ia.data.length;

  let colision = false;

  for (let i = 0; i < length; i += resolution) {
    if (!ia.data[i + 3] || !ib.data[i + 3]) continue;

    colision = true;
    break;
  }

  return colision;
};
