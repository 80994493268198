import React from 'react';
import PropTypes from 'prop-types';
import pacmanSVG from '../../../../assets/pacman.svg';
import redGhostSVG from '../../../../assets/red_ghost.svg';
import pinkGhostSVG from '../../../../assets/pink_ghost.svg';
import digitalVitaminImage from '../../../../game/assets/digital.png';
import creativeVitaminImage from '../../../../game/assets/criat.png';

import { GAME_SCREEN, GAME_SCREEN_STATE } from '../../../../common/constants';

import './intro.css';

const textSVGBaseProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const Intro = ({ setGameScreenState }) => {
  return (
    <>
      <img id="pacman-svg" src={pacmanSVG} alt="pacman svg" />
      <img id="red-ghost-svg" src={redGhostSVG} alt="red ghost svg" />
      <img id="pink-ghost-svg" src={pinkGhostSVG} alt="pink ghost svg" />
      <img id="digital-prize-img" src={digitalVitaminImage} />{' '}
      <img id="creative-prize-img" src={creativeVitaminImage} />
      <svg viewBox="0 0 1581.93 149.6" id="pellet-box">
        <circle cx="450" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="500" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="550" cy="90" r="10" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="600" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="735" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="765" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="895" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="925" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="1055" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
        <circle cx="1085" cy="90" r="5" stroke="white" strokeWidth="1" fill="white" />
      </svg>
      <svg id="intro" viewBox="0 0 1356.98 449.89">
        <text x="50%" y="40%" {...textSVGBaseProps} className="btn text mid white">
          {GAME_SCREEN.INTRO.EAT}
        </text>
        <text x="50%" y="45%" {...textSVGBaseProps} className="btn text mid white">
          {GAME_SCREEN.INTRO.RUN}
        </text>
        <text x="50%" y="60%" {...textSVGBaseProps} className="btn text mid yellow">
          {GAME_SCREEN.INTRO.CATCH}
        </text>
        <text x="50%" y="65%" {...textSVGBaseProps} className="btn text mid yellow">
          {GAME_SCREEN.INTRO.BUSINESS}
        </text>
        <text
          onClick={() => setGameScreenState(GAME_SCREEN_STATE.LOGIN)}
          x="50%"
          y="85%"
          {...textSVGBaseProps}
          className="btn text white"
        >
          <animate
            attributeType="XML"
            attributeName="fill"
            values="#fff;#ffa500;#fff"
            dur="1.5s"
            repeatCount="indefinite"
          />
          {GAME_SCREEN.INTRO.PLAY}
        </text>
      </svg>
    </>
  );
};

Intro.propTypes = {
  setGameScreenState: PropTypes.func.isRequired,
};

export default Intro;
