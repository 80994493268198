import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CountdownScreen from './CountdownScreen';

const CountdownScreenContainer = ({ timeTillDate, timeFormat, setSelectedScreen }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const then = moment(timeTillDate, timeFormat);
      const now = moment();

      const duration = moment.duration(then.diff(now));

      const currentDays = duration.days() + duration.months() * 31;
      const currentHours = duration.hours();
      const currentMinutes = duration.minutes();

      setDays(currentDays);
      setHours(currentHours);
      setMinutes(currentMinutes);
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <CountdownScreen
      days={days}
      hours={hours}
      minutes={minutes}
      setSelectedScreen={setSelectedScreen}
    />
  );
};

CountdownScreenContainer.propTypes = {
  timeTillDate: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  setSelectedScreen: PropTypes.func.isRequired,
};

export default CountdownScreenContainer;
