import React from 'react';
import PropTypes from 'prop-types';
import helioMktSVG from '../../assets/helio.svg';
import { ARCADE_FOOTER, SCREENS } from '../../common/constants';

import './arcade-footer.css';

const footerText = {
  yyLine: 24,
  xxPolitics: 30,
  xxConditions: 470.28,
  xxContacts: 900.56,
  xxPoweredBy: 1250,
};

const helioMarketeerURL = 'https://heliocabralmarketeer.pt/?utm_source=vitman&utm_medium=footer';

const ArcadeFooter = ({ setSelectedScreen, disabled }) => (
  <div id="footer">
    <svg viewBox="0 0 1432.21 39.26">
      <text
        x={footerText.xxPolitics}
        y={footerText.yyLine}
        className={`text btn ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        onClick={() => !disabled && setSelectedScreen(SCREENS.POLICY_SCREEN)}
      >
        {ARCADE_FOOTER.POLITICS}
      </text>
      <text
        x={footerText.xxConditions}
        y={footerText.yyLine}
        className={`text btn ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        onClick={() => !disabled && setSelectedScreen(SCREENS.CONDITIONS_SCREEN)}
      >
        {ARCADE_FOOTER.CONDITIONS}
      </text>
      <text
        x={footerText.xxContacts}
        y={footerText.yyLine}
        className={`text btn ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        onClick={() => !disabled && setSelectedScreen(SCREENS.CONTACTS_SCREEN)}
      >
        {ARCADE_FOOTER.CONTACTS}
      </text>
      <text x={footerText.xxPoweredBy} y={footerText.yyLine} className="text btn">
        {ARCADE_FOOTER.POWERED_BY}
      </text>
    </svg>
    <a id="helio-mkt" href={helioMarketeerURL} target="_blank" rel="noreferrer">
      <img src={helioMktSVG} alt="helio mkt" />
    </a>
  </div>
);

ArcadeFooter.propTypes = {
  setSelectedScreen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ArcadeFooter;
