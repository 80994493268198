import React from 'react';
import { CONTACTS_SCREEN } from '../../../common/constants';

import './contacts-screen.css';

const whatsAppLink =
  'https://wa.me/message/DKVNEX6H4QEQB1?utm_source=website&utm_medium=vitman&utm_campaign=whatsapp';

const facebookGroupLink =
  'https://www.facebook.com/groups/795001131269552/?utm_source=website&utm_medium=vitman&utm_campaign=grupo_facebook';

const linkedinPageLink =
  'https://www.linkedin.com/showcase/vit-man/?utm_source=website&utm_medium=vitman&utm_campaign=pagina_linkedin';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const anchorLinkBaseProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const email = 'hello@vitman.pt';

const ContactsScreen = () => (
  <div id="contacts-screen">
    <svg viewBox="0 0 1676.15 555.71">
      <text x="50%" y="10%" {...baseSVGTextProps} className="bigtext white">
        {CONTACTS_SCREEN.CONTACTS}
      </text>
      <a xlinkHref={`mailto:${email}`} {...anchorLinkBaseProps}>
        <text x="50%" y="30%" {...baseSVGTextProps} className="text yellow">
          {CONTACTS_SCREEN.EMAIL}
        </text>
      </a>

      <text x="50%" y="50%  " {...baseSVGTextProps} className="text green">
        {CONTACTS_SCREEN.WHATSAPP}
      </text>
      <a xlinkHref={whatsAppLink} {...anchorLinkBaseProps}>
        <text x="50%" y="60%" {...baseSVGTextProps} className="text yellow">
          {CONTACTS_SCREEN.CELLPHONE_NUM}
        </text>
      </a>
      <a xlinkHref={facebookGroupLink} {...anchorLinkBaseProps}>
        <text x="50%" y="80%  " {...baseSVGTextProps} className="text blue_face">
          {CONTACTS_SCREEN.FACEBOOK_GROUP}
        </text>
      </a>
      <a xlinkHref={linkedinPageLink} {...anchorLinkBaseProps}>
        <text x="50%" y="90%  " {...baseSVGTextProps} className="text blue_link">
          {CONTACTS_SCREEN.LINKEDIN_PAGE}
        </text>
      </a>
    </svg>
  </div>
);

export default ContactsScreen;
