const matrix = [
  [
    'AA',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'CV',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'TW',
    'SS',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    'CA',
    'CC',
    '  ',
    'CA',
    'CE',
    'CC',
    '  ',
    'CJ',
    '  ',
    'CA',
    'CE',
    'CC',
    '  ',
    'CA',
    'CC',
    '  ',
    'RW',
  ],
  [
    'LW',
    'PP',
    'CB',
    'CD',
    '  ',
    'CB',
    'CF',
    'CD',
    '  ',
    'CJ',
    '  ',
    'CB',
    'CF',
    'CD',
    '  ',
    'CB',
    'CD',
    'PP',
    'RW',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    'CG',
    'CH',
    '  ',
    'CI',
    '  ',
    'CG',
    'CM',
    'CS',
    'CM',
    'CH',
    '  ',
    'CI',
    '  ',
    'CG',
    'CH',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'FF',
    'BW',
    'BW',
    'II',
    '  ',
    'CK',
    'CM',
    'CH',
    '__',
    'CL',
    '__',
    'CG',
    'CM',
    'CN',
    '  ',
    'LL',
    'BW',
    'BW',
    'DD',
  ],
  [
    'G ',
    'G ',
    'G ',
    'LW',
    '  ',
    'CJ',
    '__',
    '__',
    '__',
    '__',
    '__',
    '__',
    '__',
    'CJ',
    '  ',
    'RW',
    'G ',
    'G ',
    'G ',
  ],
  [
    'TW',
    'TW',
    'TW',
    'UU',
    '  ',
    'CL',
    '__',
    'CO',
    'CH',
    'GD',
    'CG',
    'CQ',
    '__',
    'CL',
    '  ',
    'OO',
    'TW',
    'TW',
    'TW',
  ],
  [
    'V ',
    '__',
    '__',
    '__',
    '  ',
    '__',
    '__',
    'CJ',
    '__',
    '__',
    '__',
    'CJ',
    '__',
    '__',
    '  ',
    '__',
    '__',
    '__',
    'V ',
  ],
  [
    'BW',
    'BW',
    'BW',
    'II',
    '  ',
    'CI',
    '__',
    'CP',
    'CM',
    'CM',
    'CM',
    'CR',
    '__',
    'CI',
    '  ',
    'LL',
    'BW',
    'BW',
    'BW',
  ],
  [
    'G ',
    'G ',
    'G ',
    'LW',
    '  ',
    'CJ',
    '__',
    '__',
    '__',
    '__',
    '__',
    '__',
    '__',
    'CJ',
    '  ',
    'RW',
    'G ',
    'G ',
    'G ',
  ],
  [
    'AA',
    'TW',
    'TW',
    'UU',
    '  ',
    'CL',
    '__',
    'CG',
    'CM',
    'CT',
    'CM',
    'CH',
    '__',
    'CL',
    '  ',
    'OO',
    'TW',
    'TW',
    'SS',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    'CG',
    'CQ',
    '  ',
    'CG',
    'CM',
    'CH',
    '  ',
    'CL',
    '  ',
    'CG',
    'CM',
    'CH',
    '  ',
    'CO',
    'CH',
    '  ',
    'RW',
  ],
  [
    'LW',
    'PP',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    'PP',
    'RW',
  ],
  [
    'CX',
    'CH',
    '  ',
    'CL',
    '  ',
    'CI',
    '  ',
    'CG',
    'CM',
    'CT',
    'CM',
    'CH',
    '  ',
    'CI',
    '  ',
    'CL',
    '  ',
    'CG',
    'CZ',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    'CJ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    'CG',
    'CM',
    'CM',
    'CU',
    'CM',
    'CH',
    '  ',
    'CL',
    '  ',
    'CG',
    'CM',
    'CU',
    'CM',
    'CM',
    'CH',
    '  ',
    'RW',
  ],
  [
    'LW',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    '  ',
    'RW',
  ],
  [
    'FF',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'BW',
    'DD',
  ],
];

export const wallSymbols = {
  LW: 'LW',
  TW: 'TW',
  BW: 'BW',
  FF: 'FF',
  DD: 'DD',
  RW: 'RW',
  SS: 'SS',
  AA: 'AA',
  OO: 'OO',
  LL: 'LL',
  UU: 'UU',
  II: 'II',
  CA: 'CA',
  CB: 'CB',
  CC: 'CC',
  CD: 'CD',
  CE: 'CE',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CJ: 'CJ',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CP: 'CP',
  CQ: 'CQ',
  CR: 'CR',
  CS: 'CS',
  CT: 'CT',
  CU: 'CU',
  CV: 'CV',
  CX: 'CX',
  CZ: 'CZ',
  'G ': 'G ',
};

export const otherSymbols = {
  GD: 'GD',
};

export const isWall = (wall) => wall in wallSymbols;

export const pathpoints = () => {
  const points = [];
  matrix.forEach((row, ri) =>
    row.forEach((col, ci) => {
      if (!(matrix[ri][ci] in wallSymbols)) {
        points.push({ x: ci, y: ri });
      }
    })
  );
  return points;
};

export default matrix;
