import { inputKeys } from './inputKeys';

const minExposureTime = 2;

export function introScreen(ctx, progressTimeSeconds, game) {
  ctx.fillText('PERSONAGEM  / NICKNAME', 20 + 100, 20 + 125);

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime) {
    game.blinky.sprites[inputKeys.Right].draw(50, 160, ctx);
  }

  ctx.fillStyle = '#FF0000';

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime) {
    ctx.fillText('- SHADOW', 20 + 100, 20 + 170);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime) {
    ctx.fillText('"CONCORRENCIA"', 20 + 310, 20 + 170);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 1) {
    game.pinky.sprites[inputKeys.Right].draw(50, 220, ctx);
  }

  ctx.fillStyle = '#FFB8FF';

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 1) {
    ctx.fillText('- SPEEDY', 20 + 100, 20 + 230);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 1) {
    ctx.fillText('"CRISE"', 20 + 345, 20 + 230);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 2) {
    game.inky.sprites[inputKeys.Right].draw(50, 280, ctx);
  }

  ctx.fillStyle = '#00FFFF';

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 2) {
    ctx.fillText('- BASHFUL', 20 + 100, 20 + 290);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 2) {
    ctx.fillText('"MEDO"', 20 + 345, 20 + 290);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 3) {
    game.clyde.sprites[inputKeys.Right].draw(50, 340, ctx);
  }

  ctx.fillStyle = '#FFB852';

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 3) {
    ctx.fillText('- POKEY', 20 + 100, 20 + 350);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 3) {
    ctx.fillText('"MINDSET"', 20 + 345, 20 + 350);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 4) {
    game.blinky.sprites[inputKeys.Right].draw(50 + 150, 450, ctx);
    game.pinky.sprites[inputKeys.Right].draw(90 + 150, 450, ctx);
    game.inky.sprites[inputKeys.Right].draw(130 + 150, 450, ctx);
    game.clyde.sprites[inputKeys.Right].draw(170 + 150, 450, ctx);
    game.pacman.sprites[inputKeys.Right].draw(230 + 150, 450, ctx);
  }

  ctx.fillStyle = 'white';

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 5) {
    ctx.beginPath();
    ctx.arc(20 + 200, 20 + 540, 0.25 * game.unitSize, 0, 2 * Math.PI);
    ctx.fill();

    ctx.fillText('10 PTS', 20 + 240, 20 + 550);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 6) {
    ctx.beginPath();
    ctx.arc(20 + 200, 20 + 590, 0.5 * game.unitSize, 0, 2 * Math.PI);
    ctx.fill();

    ctx.fillText('50 PTS', 20 + 240, 20 + 600);
  }

  if (progressTimeSeconds - game.lastSavedTime > minExposureTime + 7) {
    ctx.fillText('@ 2020 VITMAN', 20 + 150, 20 + 700);
  }
}

export function startScreen(ctx, progressTimeSeconds) {
  ctx.fillStyle = '#ffbf62';

  if (progressTimeSeconds % 2 === 1) {
    ctx.fillText('PRESSIONA A BARRA DE ESPACO', 100, 250);
  }

  ctx.fillStyle = '#00e4ff';

  ctx.fillText('APENAS 1 JOGADOR', 160, 350);

  ctx.fillStyle = '#ffbfb5';

  ctx.fillText('--APANHAR VITAMINAS--', 130, 450);

  ctx.fillStyle = '#ffc0ff';

  ctx.fillText('@ 2020 VITMAN', 20 + 150, 550);
}

export function endScreen(ctx, progressTimeSeconds, game) {
  ctx.fillStyle = '#00e4ff';
  ctx.fillText('O JOGO TERMINOU', 170, 150);

  ctx.fillStyle = '#ffbfb5';
  ctx.fillText('PONTUACAO: ' + game.points, 175, 250);
  ctx.fillText('VITAMINAS: ' + game.vitamins.length, 175, 300);

  ctx.fillStyle = '#ffbf62';
  ctx.fillText('VERIFICA O TEU EMAIL', 125, 420);

  ctx.fillStyle = '#ffbf62';
  ctx.fillText('PARTILHA', 125, 420);

  ctx.fillStyle = '#ffc0ff';
  ctx.fillText('@ 2020 VITMAN - HELIO MKT', 20 + 70, 650);
}
