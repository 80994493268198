import React from 'react';
import PropTypes from 'prop-types';
import { GAME_SCREEN, GAME_SCREEN_STATE } from '../../../../common/constants';

import './login.css';
import { IN_GAME_EGOI } from '../../../../game/js/Game';

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://vitman.pt/';

const LOGIN_PATH = 'egoi/login';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const Login = ({ setGameScreenState, setLoginInfo }) => {
  const egoiRequest = (firstName, lastName, email) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };

    fetch(`${BASE_URL}${LOGIN_PATH}`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((body) => {
        if (body.error) {
          throw { status: body.error, message: body.message };
        } else {
          setLoginInfo({
            contactId: body.contactId,
            firstName,
            lastName,
            email,
            lives: body.contactLives,
            points: body.contactPoints,
            previousPlayed: body.contactInGame === IN_GAME_EGOI.SHARED_GAME,
          });
          setGameScreenState(GAME_SCREEN_STATE.KEYS_PICKER);
        }
      })
      .catch((error) => {
        if (error.message === 'Invalid Login')
          setGameScreenState(GAME_SCREEN_STATE.LOGIN_ERROR_INVALID_LOGIN);
        else if (error.message === 'Cannot play at the moment')
          setGameScreenState(GAME_SCREEN_STATE.LOGIN_ERROR_NO_LIVES);
        else setGameScreenState(GAME_SCREEN_STATE.LOGIN_ERROR_BASE);
      });
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    const firstName = evt.target.firstName.value;
    const secondName = evt.target.secondName.value;
    const email = evt.target.email.value;

    setGameScreenState(GAME_SCREEN_STATE.LOGGING_IN);

    setTimeout(() => egoiRequest(firstName, secondName, email), 1500);
  };

  return (
    <svg id="login" viewBox="0 0 1356.98 449.89">
      <text x="50%" y="10%" {...baseSVGTextProps} className="text yellow">
        {GAME_SCREEN.LOGIN.FILL}
      </text>
      <foreignObject x="25%" y="20%">
        <form onSubmit={onSubmit}>
          <input
            className="login-input"
            type="text"
            placeholder={GAME_SCREEN.LOGIN.FIRST_NAME}
            name="firstName"
            required
          />
          <input
            className="login-input"
            type="text"
            placeholder={GAME_SCREEN.LOGIN.LAST_NAME}
            name="secondName"
            required
          />
          <input
            className="login-input"
            type="email"
            placeholder={GAME_SCREEN.LOGIN.EMAIL}
            name="email"
            required
          />
          <div id="policy-wrapper">
            <input
              className="login-input checkbox"
              type="checkbox"
              name="policy"
              id="policy"
              required
            />
            <label htmlFor="policy">{GAME_SCREEN.LOGIN.POLICY}</label>
          </div>
          <input id="submit-btn" type="submit" value={GAME_SCREEN.LOGIN.REGISTER} />
        </form>
      </foreignObject>
    </svg>
  );
};

Login.propTypes = {
  setGameScreenState: PropTypes.func.isRequired,
  setLoginInfo: PropTypes.func.isRequired,
};

export default Login;
