import React from 'react';
import PropTypes from 'prop-types';

import { GAME_SCREEN, GAME_SCREEN_STATE } from '../../../../common/constants';

import './keys-info.css';

const textSVGBaseProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const KeysInfo = ({ setGameScreenState }) => {
  return (
    <svg id="keys-picker" viewBox="0 0 1356.98 449.89">
      <text x="50%" y="20%" {...textSVGBaseProps} className="text yellow">
        {GAME_SCREEN.KEYS_INFO.KEYS}
      </text>
      <foreignObject x="20%" y="30%">
        <div className="keys-list">
          <div className="key-info">
            {GAME_SCREEN.KEYS_INFO.UP} <kbd>&uarr;</kbd> | <kbd>K</kbd>
          </div>
          <div className="key-info">
            {GAME_SCREEN.KEYS_INFO.DOWN} <kbd>&darr;</kbd> | <kbd>J</kbd>
          </div>
          <div className="key-info">
            {GAME_SCREEN.KEYS_INFO.LEFT} <kbd>&larr;</kbd> | <kbd>H</kbd>
          </div>
          <div className="key-info">
            {GAME_SCREEN.KEYS_INFO.RIGHT} <kbd>&rarr;</kbd> | <kbd>L</kbd>
          </div>
          <div className="key-info">
            {GAME_SCREEN.KEYS_INFO.SKIP_INTRO} <kbd>SPACE</kbd>
          </div>
          <div
            onClick={() => setGameScreenState(GAME_SCREEN_STATE.PLAY)}
            className="continue-to-game"
          >
            {GAME_SCREEN.KEYS_INFO.CONTINUE}
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};

KeysInfo.propTypes = {
  setGameScreenState: PropTypes.func.isRequired,
};

export default KeysInfo;
