import React from 'react';

import './policy-screen.css';

const email = 'hello@vitman.pt';

const whatsAppLink =
  'https://wa.me/message/DKVNEX6H4QEQB1?utm_source=website&utm_medium=vitman&utm_campaign=whatsapp';

const anchorLinkBaseProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const PolicyScreen = () => (
  <div id="policy-screen">
    <div id="scrollable-text">
      <p className="title strong-yellow">Olá. Bem-vindo ao VIT-MAN.</p>
      <br />
      <p>
        Ao acederes e fazer o registo no website do VIT-MAN reconheces que tomaste conhecimento e
        concordas com esta Política de Privacidade & Cookies, que definem os termos e condições de
        utilização do jogo.
      </p>
      <br />
      <p>
        As informações recolhidas são utilizadas para a comunicação de temas relacionados com o
        VIT-MAN, como comunicações de prémios, novidades, passatempos, novos parceiros, campanhas
        especiais, entre outros.
      </p>
      <br />
      <p>
        As informações recolhidas podem também ser utilizadas para comunicações de Marketing do
        hélio cabral | marketeer, caso aceite previamente.
      </p>
      <br />
      <p>Todos os dados recolhidos não serão divulgados sem o teu consentimento.</p>
      <br />
      <p>
        A qualquer momento, tens o direito de solicitar para aceder aos seus dados, assim como
        solicitar a sua retificação, eliminação, limitação de tratamento, oposição ao tratamento e
        portabilidade.
      </p>
      <br />
      <p className="title strong-yellow">
        Como podes realizar esta solicitação? <br />
      </p>
      <br />
      <p>
        <ul className="list">
          <li>
            Por e-mail:{' '}
            <a className="email-link" href={`mailto:${email}`} {...anchorLinkBaseProps}>
              hello@vitman.pt
            </a>
          </li>
          <li>Por correio: Rua Doutor Carlos Pires Felgueiras, 218 Sala 4 | Cidade da Maia</li>
          <li>
            Por telefone:{' '}
            <a className="whatsapp-link" href={whatsAppLink} {...anchorLinkBaseProps}>
              +351 918 261 006
            </a>
          </li>
        </ul>
      </p>
      <br />
      <p>
        É possível revelar informações pessoalmente identificáveis sob circunstâncias especiais e
        devidamente fundamentadas, tais como no cumprimento de ordens judiciais que assim o exigem.
      </p>
      <br />
      <p>
        Tanto o website do VIT-MAN como o seu conteúdo podem ser atualizados ou modificados sem
        aviso prévio. Ao aceder e usar o website do VIT-MAN depois dessa atualização ou alteração dá
        o seu acordo a essas mesmas atualizações e alterações, pelo que deverá rever esta Política
        de Privacidade periodicamente.
      </p>
      <br />
      <p>
        O website do VIT-MAN e o seu conteúdo são da propriedade do hélio cabral | marketeer,
        controlados por mim e quem trabalha comigo e estão protegidos por direitos de autor. No
        entanto é permitida a partilha do website.
      </p>
      <br />
      <p>
        As marcas registadas de produtos e/ou serviços, nomes comerciais e logótipos deste site
        estão protegidas, não podendo ser utilizados sem o prévio consentimento, por e-mail. O
        acesso ao website do VIT-MAN não confere qualquer direito relativamente às marcas registadas
        de produtos e serviços, nomes comerciais e logótipos referidos sem a minha autorização.
      </p>
      <br />
      <p>
        Apesar de todos os esforços nesse sentido, não posso garantir nem me responsabilizar que o
        website e o seu conteúdo estejam livres de vírus ou de outras interferências que tenham
        propriedades de contaminação e destruição. Nem eu nem qualquer entidade envolvida na
        elaboração ou apresentação do website do VIT-MAN podem ser responsabilizadas por qualquer
        dano, direto ou indireto, acidental ou subsequente que ocorra devido ao acesso, utilização,
        incapacidade de utilização, erro ou omissão deste site ou do seu conteúdo.
      </p>
      <br />
      <p>
        Qualquer reclamação relativa ao website do VIT-MAN e ao seu conteúdo rege-se pela lei
        portuguesa. Ao aceder a este website concorda que os tribunais portugueses são os únicos com
        competência para a resolução de conflitos, com expressa exclusão de qualquer outro.
      </p>
      <br />
      <p className="title strong-yellow">Cookies</p>
      <br />
      <p>
        Os cookies são pequenos ficheiros de dados que são instalados no computador do utilizador ou
        qualquer outro dispositivo, quando visita um website, e são usados para registar informação
        acerca da sua navegação com o intuito de a facilitar e tornar mais agradável. Estes
        ficheiros são armazenados no terminal do utilizador, contêm dados anónimos e não danificam o
        seu computador. São utilizados para registar as preferências do utilizador, como a língua
        selecionada, os dados de acesso ou a personalização da página, quer durante a navegação,
        quer em futuras visitas.
      </p>
      <br />
      <p>
        O servidor web regista e armazena apenas as informações por definição usualmente registadas
        pelo servidor: o Endereço de Protocolo Internet pelo qual o utilizador teve acesso à
        internet; a data e a duração do acesso do utilizador ao nosso website.
      </p>
      <br />
      <p>
        Nos termos da Lei n.º 41/2004 de 18 de Agosto, o armazenamento de informações e a
        possibilidade de acesso à informação armazenada no equipamento terminal de um utilizador
        (nomeadamente através de cookies) apenas são realizados por mim caso o utilizador tenha dado
        o seu consentimento prévio e expresso à instalação dos cookies no seu equipamento.
      </p>
      <br />
      <p>
        Em caso de bloqueio ou não autorização para a utilização de cookies, não será armazenado
        qualquer cookie no seu equipamento, mas não está garantido o bom funcionamento do nosso
        website, não podendo o utilizador retirar o máximo benefício dos conteúdos disponibilizados,
        sendo que em cada novo acesso ao nosso website, será sempre solicitada autorização para a
        utilização de cookies.
      </p>
      <br />
      <p>
        Assiste ao utilizador o direito de retirar em qualquer momento o consentimento relacionado
        com a presente Política de Cookies, eliminando os cookies armazenados no seu computador
        através das opções de configuração do seu navegador da Internet.
      </p>
      <br />
      <p className="title strong-yellow">Diferentes tipos de Cookies e as suas funções</p>
      <br />
      <p>
        Para os utilizadores que se registam no website do VIT-MAN, armazenamos as informações
        pessoais fornecidas, como nome e e-mail. Todos os utilizadores vão podem ver, editar ou
        excluir as suas informações pessoais a qualquer momento. Quem gere o website do VIT-MAN
        também pode ver e editar essas informações, se necessário.
      </p>
      <br />
      <p className="table">
        <table>
          <thead>
            <tr>
              <th className="strong-yellow">Categoria</th>
              <th className="strong-yellow">Objetivo</th>
              <th className="strong-yellow">Exemplos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cookies Essenciais</td>
              <td>
                Estes cookies são necessários para a operação e bom funcionamento do website. Eles
                permitem garantir que não terá problemas em navegar no website e proporcionar a
                melhor experiência.
              </td>
              <td>Google Analytics</td>
            </tr>
            <tr>
              <td>Cookies de Funcionalidade</td>
              <td>
                Estes cookies ajudam a personalizar a sua experiência no nosso website, tendo base
                em atividades de acessos anteriores.
              </td>
            </tr>
            <tr>
              <td>Cookies de Desempenho</td>
              <td>
                Estes cookies permitem saber de onde visitou o website, por exemplo, Google, Bing ou
                um website afiliado. Estes cookies são usados para informações internas que permitem
                saber a origem dos acessos para visitar o website. Estes cookies são usados para
                determinar se chegou ao website através de um clique num link de publicidade. <br />
                Estes cookies são usados para ajudar a alcançá-lo com publicidade paga relevante por
                meio do Google com base nas atividades anteriores no website. As informações
                recolhidas por estes cookies, são usadas para ajudar a determinar onde os nossos
                anúncios serão mais eficazes online.
              </td>
              <td>Google Ads & Remarketing</td>
            </tr>
            <tr>
              <td>Cookies para Segmentação</td>
              <td>
                Estes cookies permitem oferecer anúncios personalizados por meio da rede de Display
                da Google e ajudam a oferecer anúncios personalizados com base em visitas anteriores
                ao website.
              </td>
              <td>Google Display Remarketing Cookie</td>
            </tr>
            <tr>
              <td>Redes Sociais/Cookies de Remarketing</td>
              <td>
                Estes cookies permitem-nos oferecer anúncios personalizados através de plataformas
                sociais, incluindo Facebook, Instagram e LinkedIn. <br /> Os anúncios que exibimos
                baseiam-se na sua atividade prévia no website, nas transações feitas e nos produtos
                visualizados.
              </td>
              <td>Facebook Remarketing Ads Instagram LinkedIn</td>
            </tr>
          </tbody>
        </table>
      </p>
      <br />
      <p className="title strong-red">Alterações à Política de Privacidade e Cookies</p>
      <br />
      <p>
        Qualquer alteração à nossa Política de Privacidade e Cookies será devidamente publicitada
        mediante aviso publicado no nosso website, sendo que, sempre que haja alguma alteração será
        solicitada a aceitação expressa da Política de Cookies na versão alterada.
      </p>
    </div>
  </div>
);

export default PolicyScreen;
