import Ghost, { GHOST_MODE } from './Ghost';
import { blueDown, blueUp, blueRight, blueLeft, vitamins } from './spriteSheetPoints';
import { inputKeys } from './inputKeys';
import Sprite from './Sprite';
import Maze from './Maze';
import { pointInRadius, isPointInRadius } from './algorithms';

const SpriteFrameSpeed = 7;
const inkyImage = document.getElementById('ghosts');
const scatterPoints = [
  { x: 17, y: 20 },
  { x: 13, y: 16 },
  { x: 10, y: 20 },
];
const comunicationV = document.getElementById('comu_sprite');

class Inky extends Ghost {
  constructor(game, x, y, velocity, direction) {
    super(
      game,
      x,
      y,
      velocity,
      direction,
      {
        [inputKeys.Right]: new Sprite(
          game.unitSize,
          game.unitSize,
          inkyImage,
          blueRight,
          blueRight.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Left]: new Sprite(
          game.unitSize,
          game.unitSize,
          inkyImage,
          blueLeft,
          blueLeft.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Up]: new Sprite(
          game.unitSize,
          game.unitSize,
          inkyImage,
          blueUp,
          blueUp.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Down]: new Sprite(
          game.unitSize,
          game.unitSize,
          inkyImage,
          blueDown,
          blueDown.length,
          SpriteFrameSpeed
        ),
      },
      scatterPoints
    );

    this.vitaminSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        comunicationV,
        vitamins,
        vitamins.length,
        SpriteFrameSpeed
      ),
    };
    this.radius = 5;
  }

  reset() {
    this.resetGhost();
    this.radius = 5;
  }

  update(progress) {
    if (this.ghostmode === GHOST_MODE.CHASE) {
      const inkyMazePos = Maze.getMazePosition(this.game, this.x, this.y);
      const pacmanMazePos = Maze.getMazePosition(this.game, this.game.pacman.x, this.game.pacman.y);

      if (
        !isPointInRadius(
          pacmanMazePos.x,
          pacmanMazePos.y,
          this.targetPoint.x,
          this.targetPoint.y,
          this.radius
        ) ||
        (inkyMazePos.x === this.targetPoint.x && inkyMazePos.y === this.targetPoint.y)
      ) {
        this.targetPoint = pointInRadius(pacmanMazePos.x, pacmanMazePos.y, this.radius);
      }
    }

    this.updateGhost(progress);
  }
}

export default Inky;
