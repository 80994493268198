import React, { useEffect, useRef } from 'react';
import Game, { GAMEMODE } from './js/Game';
import PropTypes from 'prop-types';

import './css/style.css';
import { GAME_SCREEN_STATE } from '../common/constants';

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://vitman.pt/';

const UPDATE_PATH = 'egoi/update';

export const updatePointsPrize = (contactId, points, prize, inGame, setGameMode) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const body = {
    contactId,
    points,
    prize,
    inGame,
  };

  fetch(`${BASE_URL}${UPDATE_PATH}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: headers,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw { status: res.status, message: res.statusText };
      }
    })
    .then(() => {})
    .catch(() => {
      setGameMode(GAMEMODE.ERROR);
    });
};

export const loseLiveRequest = (contactId, lives, setGameMode) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const body = {
    contactId,
    lives: lives - 1,
  };

  fetch(`${BASE_URL}${UPDATE_PATH}`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: headers,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw { status: res.status, message: res.statusText };
      }
    })
    .then(() => {})
    .catch(() => {
      setGameMode(GAMEMODE.ERROR);
    });
};

export const Canvas = ({ loginInfo, setGameScreenState, setLoginInfo, gameScreenState }) => {
  const canvasRef = useRef(null);

  const requestRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const unitSize = 30;
    const wallLineWidth = unitSize / 7.5;
    const gameWidthUnitSize = 19;
    const gameHeightUnitSize = 22;
    const gameWidth = gameWidthUnitSize * unitSize;
    const gameHeight = gameHeightUnitSize * unitSize;
    const gameStartY = 50;
    const LivesPanelWidth = 50;
    let startTime = 0;

    canvas.width = gameWidth;
    canvas.height = gameHeight + gameStartY + LivesPanelWidth;

    const { points, lives, contactId } = loginInfo;

    const game = new Game(
      unitSize,
      gameWidth,
      gameHeight,
      gameStartY,
      wallLineWidth,
      parseInt(points),
      parseInt(lives),
      contactId,
      setGameScreenState,
      loginInfo,
      setLoginInfo,
      canvas
    );

    game.buildGame();

    function main(timestamp) {
      if (gameScreenState !== GAME_SCREEN_STATE.PLAY) {
        return null;
      }

      context.clearRect(0, 0, canvas.width, canvas.height);

      if (!startTime) startTime = timestamp;

      let progress = timestamp - startTime;

      game.update(progress, context);
      game.draw(context, progress);

      if (game.gamemode !== GAMEMODE.END) requestRef.current = window.requestAnimationFrame(main);
    }

    requestRef.current = window.requestAnimationFrame(main);

    return () => {
      game.pacman.handler.removeKeys();
      window.cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return <canvas id="canvinhas" ref={canvasRef} />;
};

Canvas.propTypes = {
  setGameScreenState: PropTypes.func.isRequired,
  setLoginInfo: PropTypes.func.isRequired,
  loginInfo: PropTypes.shape({
    points: PropTypes.number,
    lives: PropTypes.number,
    contactId: PropTypes.string,
  }),
  width: PropTypes.number,
  height: PropTypes.height,
  gameScreenState: PropTypes.string,
};

export default Canvas;
