export const getShareEmail = (
  friendName
) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
  <head>
    <link
      href="https://fonts.googleapis.com/css?family=Montserrat"
      rel="stylesheet"
      type="text/css"
    /></head
  ><img
    border="0"
    src="http://comunicacao.vitman.pt/statst.php?l=4c3d55c2796a55b39fc3023791fa0aa8&u=2e9cdcd05c&ca_id=164121&cl_id=878175&l_id=1&m_id=214116&hf=2256&g=1&i=1"
    style="
      width: 3px !important;
      height: 2px !important;
      overflow: hidden;
      border: 0px solid transparent;
      margin: 0;
      line-height: 2px;
      mso-margin-top-alt: 2px;
    "
    width="3"
    height="2"
    align="middle"
    border="0"
    alt=""
  />
  <head>
    <style rel="stylesheet" type="text/css">
      @media screen and (max-width: 600px) {
        .email-container {
          width: 100% !important;
          margin: auto !important;
        }
        .fluid {
          max-width: 100% !important;
          height: auto !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
        .stack-column,
        .stack-column-center {
          display: block !important;
          width: 100% !important;
          max-width: 100% !important;
          direction: ltr !important;
        }
        .stack-column-center {
          text-align: center !important;
        }
        .center-on-narrow {
          text-align: center !important;
          display: block !important;
          margin-left: auto !important;
          margin-right: auto !important;
          float: none !important;
        }
        table.center-on-narrow {
          display: inline-block !important;
        }
      }
      @media only screen and (max-width: 600px) {
        .email-container {
          width: 95% !important;
          min-width: 0 !important;
        }
        table.button.small-expanded {
          width: 100% !important;
        }
        img.fluid {
          height: auto !important;
        }
        td.columns,
        th.columns {
          box-sizing: border-box !important;
          padding: 0 !important;
          display: block !important;
          width: 100% !important;
        }
        td.columns.first,
        th.columns.first {
          padding-bottom: 20px !important;
          padding-right: 0 !important;
        }
        td.columns.middle,
        th.columns.middle {
          padding-bottom: 20px !important;
        }
        td.columns.last,
        th.columns.last {
          padding-left: 0 !important;
        }
      }
    </style>
    <style rel="stylesheet" type="text/css">
      @media only screen and (max-width: 600px) {
        .image-deff71999385ca5e1cdd62a2ef15f96c img.fluid {
          width: 100% !important;
        }
      }
    </style>
    <style rel="stylesheet" type="text/css">
      @media only screen and (max-width: 600px) {
        .image-a348197df8b89cf30fe91cbac4b21497 img.fluid {
          width: 66% !important;
        }
      }
    </style>
    <style rel="stylesheet" type="text/css">
      @media only screen and (max-width: 600px) {
        .image-1d390ce643f61e975a09dde9b19de401 img.fluid {
          width: 100% !important;
        }
      }
    </style>
    <style rel="stylesheet" type="text/css">
      @media only screen and (max-width: 600px) {
        .image-2fe91fa4d562f655ff948af9eb9f25b7 img.fluid {
          width: 100% !important;
        }
      }
    </style>
  </head>
  <body
    width="100%"
    style="padding: 0px; height: 100%; width: 100%; margin: 0px"
  >
    <!--[if mso]>
                                       <span style="display: none; max-height: 0px; overflow: hidden; mso-hide: all">
                                   <!
    [endif]--><!--[if !mso]> <!---->
    <span style="visibility: hidden; display: none !important; display: none">
      <!-- <![endif]-->

      Olá, O teu amigo ${friendName} jogou o VIT-MAN e gostou tanto da experiência, que
      te desafia a jogar também. Em que consiste o jogo? Controlando o VIT-MAN,
      o objetivo é comer todas as bolas que se encont</span
    >
    <div>
      <div _ngcontent-ymc-c0="" class="eb-mail-content">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="
            border-spacing: 0px;
            border-collapse: collapse;
            table-layout: fixed;
            margin: 0px auto;
            background-color: rgb(255, 129, 0);
            background-image: url('https://www22.e-goi.com/recursos/60719e9b035e4573fec939fce6f85516/Image/fundo-vitman-pacman.jpg');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: cover;
          "
        >
          <tbody class="cerberus-tbody">
            <tr id="674d3406-ea7e-7826-da41-d0277ccdbade">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(24, 25, 46);
                            padding: 0px 20px;
                          "
                          bgcolor="#18192E"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            data-control="paragraph"
                                            width="100%"
                                            class="paragraph-aa348e8c5564ce7478a4e7aa3b79382c"
                                            id="aa348e8c-5564-ce74-78a4-e7aa3b79382c"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    padding-top: 0px;
                                                    padding-bottom: 0px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    Olá, <br />O teu amigo ${friendName}
                                                    jogou o VIT-MAN e gostou
                                                    tanto da experiência, que te
                                                    desafia a jogar também.
                                                    <br />
                                                    <br /><span
                                                      style="color: #f3df35"
                                                      ><strong
                                                        >Em que consiste o
                                                        jogo?</strong
                                                      ></span
                                                    >
                                                    <br />Controlando o VIT-MAN,
                                                    o objetivo é comer todas as
                                                    bolas que se encontram
                                                    distribuídas pelo mercado,
                                                    enquanto evitas os quatro
                                                    fantasmas 'concorrência',
                                                    'crise', 'medo' e 'mindset',
                                                    sempre prontos para
                                                    fragilizar o teu negócio. 
                                                    <br />
                                                    <br /><span
                                                      style="color: #f3df35"
                                                      ><strong
                                                        >Como vencer os inimigos
                                                        do teu negócio?</strong
                                                      ></span
                                                    >
                                                    <br />Pela área de jogo vão
                                                    aparecer umas bolas maiores
                                                    (representam a força,
                                                    mudança, agilidade,
                                                    flexibilidade e adaptação💪)
                                                    que permitem ao VIT-MAN
                                                    caçar os fantasmas em vez de
                                                    ser caçado, mas cuidado,
                                                    apenas durante um curto
                                                    período. 
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="29a133c6-9340-eeaf-7c43-730c1f838d75">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(24, 25, 46);
                            padding: 20px;
                          "
                          bgcolor="#18192E"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            class="builder-image-control image-deff71999385ca5e1cdd62a2ef15f96c"
                                            data-control="image"
                                            width="100%"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="">
                                                  <table
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    align="center"
                                                    width=""
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td style="border: 0px">
                                                          <a
                                                            ><img
                                                              align="center"
                                                              border="0"
                                                              class="float-center fluid"
                                                              style="
                                                                display: block;
                                                                margin: 0px auto;
                                                                height: auto;
                                                                max-width: 560px;
                                                                border: 0px !important;
                                                                outline: none !important;
                                                                text-decoration: none !important;
                                                              "
                                                              id="deff7199-9385-ca5e-1cdd-62a2ef15f96c"
                                                              src="https://www22.e-goi.com/recursos/60719e9b035e4573fec939fce6f85516/Image/vit.png"
                                                              alt="vit"
                                                              height="144"
                                                              width="560"
                                                          /></a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="9e1ee4d3-869b-2e0a-49e6-54e55f00fda5">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(24, 25, 46);
                            padding: 0px 20px;
                          "
                          bgcolor="#18192E"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            data-control="paragraph"
                                            width="100%"
                                            class="paragraph-9b6f894bdaad449bd98c7823f750aa03"
                                            id="9b6f894b-daad-449b-d98c-7823f750aa03"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    padding-top: 0px;
                                                    padding-bottom: 0px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    Prepara-te para Comer,
                                                    comer, comer… Fugir dos
                                                    Fantasmas do Mercado e
                                                    Apanhar as Vitaminas que vão
                                                    alavancar o teu Negócio!
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="b15d0406-3ef5-51a1-25df-2dc3047ae5c5">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(255, 129, 0);
                            padding: 20px;
                          "
                          bgcolor="#FF8100"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            data-control="paragraph"
                                            width="100%"
                                            class="paragraph-e2d087229115c0bcd47ebe42d04b78b1"
                                            id="e2d08722-9115-c0bc-d47e-be42d04b78b1"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    padding-top: 0px;
                                                    padding-bottom: 0px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    <i
                                                      >A particularidade do
                                                      jogo ao apanhar as bolas
                                                      maiores, é que os quatro
                                                      fantasmas se transformam
                                                      nas Vitaminas de
                                                      Marketing. Nesse momento
                                                      os prémios estão à
                                                      distância da tua
                                                      estratégia, perícia,
                                                      agilidade, e claro, alguma
                                                      sorte </i
                                                    >🙂
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="e25436d8-2654-458f-52b4-07e815833eb1">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(24, 25, 46);
                            padding: 20px;
                          "
                          bgcolor="#18192E"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            class="builder-image-control image-a348197df8b89cf30fe91cbac4b21497"
                                            data-control="image"
                                            width="100%"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="">
                                                  <table
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    align="center"
                                                    width=""
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td style="border: 0px">
                                                          <a
                                                            href="https://vitman.pt/"
                                                            ><img
                                                              align="center"
                                                              border="0"
                                                              class="float-center fluid"
                                                              style="
                                                                display: block;
                                                                margin: 0px auto;
                                                                height: auto;
                                                                max-width: 369.6px;
                                                                border: 0px !important;
                                                                outline: none !important;
                                                                text-decoration: none !important;
                                                              "
                                                              id="a348197d-f8b8-9cf3-0fe9-1cbac4b21497"
                                                              src="https://www22.e-goi.com/recursos/60719e9b035e4573fec939fce6f85516/Image/jogar.png"
                                                              alt="jogar"
                                                              height="61"
                                                              width="243"
                                                          /></a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="ca578473-0771-f2cc-639b-7fa225d0de99">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(142, 34, 38);
                            padding: 20px;
                          "
                          bgcolor="#8E2226"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            align="center"
                                            class="builder-spacer-control spacer-2855ac3e33ee2056d9548136d6e93c21"
                                            data-control="spacer"
                                            style="
                                              border-spacing: 0px;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                              width: 100%;
                                              min-width: 100%;
                                              max-width: 100%;
                                              border-collapse: separate !important;
                                            "
                                            width="100%"
                                            data-compile="true"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    border-top: 1px solid
                                                      transparent;
                                                    width: 100%;
                                                    min-width: 100%;
                                                    max-width: 100%;
                                                  "
                                                  width="100%"
                                                ></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="e8b5c3c2-b02a-ff47-735d-596c517a746f">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(142, 34, 38);
                            padding: 20px;
                          "
                          bgcolor="#8E2226"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            class="builder-image-control image-1d390ce643f61e975a09dde9b19de401"
                                            data-control="image"
                                            width="100%"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="">
                                                  <table
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    align="center"
                                                    width=""
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td style="border: 0px">
                                                          <a
                                                            ><img
                                                              align="center"
                                                              border="0"
                                                              class="float-center fluid"
                                                              style="
                                                                display: block;
                                                                margin: 0px auto;
                                                                height: auto;
                                                                max-width: 560px;
                                                                border: 0px !important;
                                                                outline: none !important;
                                                                text-decoration: none !important;
                                                              "
                                                              id="1d390ce6-43f6-1e97-5a09-dde9b19de401"
                                                              src="https://www22.e-goi.com/recursos/60719e9b035e4573fec939fce6f85516/Image/imp.png"
                                                              alt="imp"
                                                              height="47"
                                                              width="560"
                                                          /></a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="80575fd9-b0fa-773d-4fb1-4ab74f79580a">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(142, 34, 38);
                            padding: 20px;
                          "
                          bgcolor="#8E2226"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            data-control="paragraph"
                                            width="100%"
                                            class="paragraph-5c1db82d512c34835b87fc1d2b683e7f"
                                            id="5c1db82d-512c-3483-5b87-fc1d2b683e7f"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    padding-top: 0px;
                                                    padding-bottom: 0px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    <strong
                                                      >IMPRÓPRIO PARA
                                                      TELEMÓVEIS</strong
                                                    >
                                                    <br />Para tornar a
                                                    experiência o mais genuína
                                                    possível, vais voltar atrás
                                                    no tempo. <br />Sim, é isso
                                                    mesmo. O VIT-MAN é apenas
                                                    jogável em num computador.
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    
                                                  </p>
                                                  <p
                                                    style="
                                                      font-family: Arial;
                                                      font-size: 13px;
                                                      color: rgb(255, 255, 255);
                                                      line-height: 200%;
                                                      text-align: left;
                                                      padding-top: 0px;
                                                      padding-bottom: 0px;
                                                      margin: 0px;
                                                      overflow: hidden;
                                                    "
                                                  >
                                                    <strong>BOA SORTE!</strong>
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="72310cbf-c589-da0d-9873-551f5ea235b1">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(255, 129, 0);
                            padding: 20px;
                          "
                          bgcolor="#FF8100"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            align="center"
                                            class="builder-spacer-control spacer-00428d4dcdd2018758ee4ccbf052e919"
                                            data-control="spacer"
                                            style="
                                              border-spacing: 0px;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                              width: 33%;
                                              min-width: 33%;
                                              max-width: 33%;
                                              border-collapse: separate !important;
                                            "
                                            width="33%"
                                            data-compile="true"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    border-top: 1px solid
                                                      transparent;
                                                    width: 33%;
                                                    min-width: 33%;
                                                    max-width: 33%;
                                                  "
                                                  width="33%"
                                                ></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr id="19b3694e-18d9-c8d8-cfb9-e6d337540567">
              <td
                class="td-container"
                style="font-size: 0px; background-color: transparent"
                valign="top"
                align="center"
              >
                <div class="builder-actions-control selected">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="email-container"
                    style="
                      border-spacing: 0px;
                      border-collapse: collapse;
                      table-layout: fixed;
                      margin: auto;
                    "
                    width="600"
                    align="center"
                    data-compile="true"
                  >
                    <tbody>
                      <tr>
                        <td
                          dir="ltr"
                          valign="top"
                          width="100%"
                          style="
                            background-color: rgb(255, 129, 0);
                            padding: 0px 40px;
                          "
                          bgcolor="#FF8100"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          dir="ltr"
                                          style="padding: 0px"
                                          valign="top"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            class="builder-image-control image-2fe91fa4d562f655ff948af9eb9f25b7"
                                            data-control="image"
                                            width="100%"
                                            data-alias="logo"
                                            data-compile="true"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="">
                                                  <table
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    align="center"
                                                    width=""
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td style="border: 0px">
                                                          <a
                                                            ><img
                                                              align="center"
                                                              border="0"
                                                              class="float-center fluid"
                                                              style="
                                                                display: block;
                                                                margin: 0px auto;
                                                                height: auto;
                                                                max-width: 520px;
                                                                border: 0px !important;
                                                                outline: none !important;
                                                                text-decoration: none !important;
                                                              "
                                                              id="2fe91fa4-d562-f655-ff94-8af9eb9f25b7"
                                                              src="https://www22.e-goi.com/recursos/60719e9b035e4573fec939fce6f85516/Image/footer.png"
                                                              alt="footer"
                                                              height="173"
                                                              width="520"
                                                          /></a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row"
                            width="100%"
                            style="
                              border-spacing: 0px;
                              border-collapse: collapse;
                              table-layout: fixed;
                              margin: 0px auto;
                            "
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="stack-column-center columns"
                                  valign="top"
                                  width="100%"
                                  style="font-weight: 400"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="row"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          class="stack-column-center columns"
                                          valign="top"
                                          width="100%"
                                          style="font-weight: 400"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  style="padding: 0px"
                                                  valign="top"
                                                >
                                                  <table
                                                    data-control="host-panel"
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                      width: auto;
                                                    "
                                                    align="center"
                                                    data-compile="true"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style="
                                                            border-right: 10px
                                                              solid transparent;
                                                          "
                                                        >
                                                          <table
                                                            data-control="link"
                                                            class="link-70cc3f7871fd2b1e8c639e35c8c71043"
                                                            id="70cc3f78-71fd-2b1e-8c63-9e35c8c71043"
                                                            data-alias="unsubscribe"
                                                            data-compile="true"
                                                            style="
                                                              border-spacing: 0px;
                                                              border-collapse: collapse;
                                                              table-layout: fixed;
                                                              margin: 0px auto;
                                                            "
                                                          >
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="row"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          class="stack-column-center columns"
                                          valign="top"
                                          width="100%"
                                          style="font-weight: 400"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  style="padding: 0px"
                                                  valign="top"
                                                >
                                                  <table
                                                    data-control="paragraph"
                                                    width="100%"
                                                    class="paragraph-9e5c2c10db701353e0b841673e530569"
                                                    data-alias="address"
                                                    id="9e5c2c10-db70-1353-e0b8-41673e530569"
                                                    data-compile="true"
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style="
                                                            padding-top: 0px;
                                                            padding-bottom: 0px;
                                                          "
                                                        >
                                                          <p
                                                            style="
                                                              font-family: 'Trebuchet MS';
                                                              font-size: 12px;
                                                              color: rgb(
                                                                255,
                                                                255,
                                                                255
                                                              );
                                                              line-height: 200%;
                                                              text-align: center;
                                                              padding-top: 0px;
                                                              padding-bottom: 0px;
                                                              margin: 0px;
                                                              overflow: hidden;
                                                            "
                                                          >
                                                            
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="row"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          class="stack-column-center columns"
                                          valign="top"
                                          width="100%"
                                          style="font-weight: 400"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  style="padding: 0px"
                                                  valign="top"
                                                >
                                                  <table
                                                    data-control="paragraph"
                                                    width="100%"
                                                    class="paragraph-17cc98a93cb5ef3d50859fa4fdc282b2"
                                                    data-alias="sender_info"
                                                    id="17cc98a9-3cb5-ef3d-5085-9fa4fdc282b2"
                                                    data-compile="true"
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style="
                                                            padding-top: 0px;
                                                            padding-bottom: 0px;
                                                          "
                                                        >
                                                          <p
                                                            style="
                                                              font-family: 'Trebuchet MS';
                                                              font-size: 12px;
                                                              color: rgb(
                                                                255,
                                                                255,
                                                                255
                                                              );
                                                              line-height: 200%;
                                                              text-align: center;
                                                              padding-top: 0px;
                                                              padding-bottom: 0px;
                                                              margin: 0px;
                                                              overflow: hidden;
                                                            "
                                                          >
                                                            Enviado por
                                                            <a
                                                              href="mailto:hello@vitman.pt"
                                                              style="
                                                                color: rgb(
                                                                  255,
                                                                  255,
                                                                  255
                                                                );
                                                                text-decoration: underline;
                                                              "
                                                              >hello@vitman.pt</a
                                                            >
                                                            para
                                                            <a
                                                              href="mailto:jfsbrito@gmail.com"
                                                              style="
                                                                color: rgb(
                                                                  255,
                                                                  255,
                                                                  255
                                                                );
                                                                text-decoration: underline;
                                                              "
                                                              >jfsbrito@gmail.com</a
                                                            >
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="row"
                                    width="100%"
                                    style="
                                      border-spacing: 0px;
                                      border-collapse: collapse;
                                      table-layout: fixed;
                                      margin: 0px auto;
                                    "
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          class="stack-column-center columns"
                                          valign="top"
                                          width="100%"
                                          style="font-weight: 400"
                                        >
                                          <table
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            style="
                                              border-spacing: 0px;
                                              border-collapse: collapse;
                                              table-layout: fixed;
                                              margin: 0px auto;
                                            "
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  dir="ltr"
                                                  style="padding: 0px"
                                                  valign="top"
                                                >
                                                  <table
                                                    align="center"
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    class="builder-stamp-control"
                                                    data-control="stamp"
                                                    data-alias="security_stamp"
                                                    data-compile="true"
                                                    style="
                                                      border-spacing: 0px;
                                                      border-collapse: collapse;
                                                      table-layout: fixed;
                                                      margin: 0px auto;
                                                    "
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style="
                                                            text-align: center !important;
                                                          "
                                                        >
                                                          <a
                                                            href="http://comunicacao.vitman.pt/certifiedby_en/?campaign_id=4c3d55c2796a55b39fc3023791fa0aa8&eg_c_email=jfsbrito@gmail.com&f=ZtBe43WgeLAm"
                                                            ><img
                                                              align="center"
                                                              class="float-center"
                                                              style="
                                                                border: 0px;
                                                                outline: none;
                                                                text-decoration: none;
                                                                width: 100px;
                                                              "
                                                              src="https://bo22.e-goi.com/images/builders/privacy/eg-privacy.png"
                                                              alt="E-goi Privacy"
                                                              width="100"
                                                          /></a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </body>
  <div style="clear: both !important; height: 0 !important"></div>
</html>
`;
