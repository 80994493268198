import Maze from './Maze';
import Pacman from './Pacman';
import Blinky from './Blinky';
import Pinky from './Pinky';
import Inky from './Inky';
import Clyde from './Clyde';
import { inputKeys } from './inputKeys';
import { pixelColision, boundingBoxDetection } from './colisions';
import { introScreen, startScreen, endScreen } from './screens';
import { loseLiveRequest, updatePointsPrize } from '../Canvas';
import { GAME_SCREEN_STATE } from '../../common/constants';

export const GAMEMODE = {
  RUNNING: 0,
  WIN: 1,
  DIE: 2,
  END: 3,
  INTRO: 4,
  START: 5,
  READY: 6,
  ERROR: 7,
};

export const IN_GAME_EGOI = {
  READY_TO_PLAY: 1,
  PLAYING: 2,
  FINNISH_PLAYING: 3,
  SHARED_GAME: 4,
};

export const VITMAN_PRIZES = {
  tec_sprite: ['5% desconto em soluções tecnológicas'],
  criat_sprite: ['1hora em consultoria'],
  off_sprite: ['10% desconto em Plano Estratégico de Marketing', '1 esferográfica'],
  digital_sprite: [
    '1hora em consultoria',
    '5% desconto em Website',
    '10% desconto em Lojas Online',
    '5% desconto em Aplicação Mobile',
    '10% desconto em Plano Estratégico de Marketing',
    'Alojamento Linux Inicial Plesk',
    'Certificados Alpha SSL',
    'Alojamento PTisp Linux Inicial Plesk',
    'Certificados PTisp Alpha SSL',
  ],
  comu_sprite: ['5% desconto em vídeo institucional'],
};

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * max + min);
};

const getRandomPrize = (vitamins) => {
  const vitaminsNumber = vitamins.length;

  let vitamin;

  if (vitaminsNumber > 1) {
    const vitaminChooser = getRandomNumber(0, vitaminsNumber - 1);
    vitamin = vitamins[vitaminChooser].static.image.id;
  } else {
    vitamin = vitamins[0].static.image.id;
  }

  const prizes = VITMAN_PRIZES[vitamin];
  const prizesNumber = prizes.length;

  let prize;

  if (prizesNumber > 1) {
    const prizeChooser = getRandomNumber(0, prizesNumber - 1);
    prize = prizes[prizeChooser];
  } else {
    prize = prizes[0];
  }

  return prize;
};

const PELLET_POINTS = 10;
const POWER_PELLET_POINTS = 50;
const GHOST_POINTS = 400;

class Game {
  constructor(
    unitSize,
    gameWidth,
    gameHeight,
    gameStartY,
    wallLineWidth,
    points,
    lives,
    contactId,
    setGameScreenState,
    loginInfo,
    setLoginInfo,
    canvas
  ) {
    this.unitSize = unitSize;
    this.gameWidth = gameWidth;
    this.gameHeight = gameHeight;
    this.gameStartY = gameStartY;
    this.wallLineWidth = wallLineWidth;

    this.gamemode = GAMEMODE.INTRO;
    this.maze = new Maze(this);
    this.pacman = undefined;
    this.blinky = undefined;
    this.pinky = undefined;
    this.inky = undefined;
    this.clyde = undefined;
    this.pellets = [];
    this.activePelletsNum = 0;
    this.powerPellets = [];
    this.points = points;
    this.vitamins = [];
    this.lastSavedTime = 0;
    this.lives = lives;
    this.prize = '';
    this.contactId = contactId;
    this.setGameScreenState = setGameScreenState;
    this.loginInfo = loginInfo;
    this.setLoginInfo = setLoginInfo;
    this.canvas = canvas;
  }

  buildGame() {
    this.maze.buildMaze(this.unitSize, this.wallLineWidth, this.gameStartY);
    this.pellets = Maze.getPelletsMatrix(this);
    this.pellets.forEach((row, ri) =>
      row.forEach((column, ci) => {
        if (this.pellets[ri][ci]) {
          this.activePelletsNum++;
        }
      })
    );
    this.powerPellets = Maze.getPowerPellets(this);
    this.activePelletsNum += this.powerPellets.length;
    this.pacman = new Pacman(this, 9, 16, 2, inputKeys.Right);
    this.blinky = new Blinky(this, 9, 8, 2, inputKeys.Up);
    this.pinky = new Pinky(this, 9, 10, 2, inputKeys.Up);
    this.inky = new Inky(this, 8, 10, 2, inputKeys.Up);
    this.clyde = new Clyde(this, 10, 10, 2, inputKeys.Up);
    this.pinky.freeze();
    this.inky.freeze();
    this.clyde.freeze();

    this.lives--;
  }

  setGameMode(gamemode) {
    this.gamemode = gamemode;
  }

  gameModeReady() {
    this.gamemode = GAMEMODE.READY;
    this.lastSavedTime = this.progressTimeSeconds;
  }

  gameModeDie() {
    this.gamemode = GAMEMODE.DIE;
    this.lastSavedTime = this.progressTimeSeconds;
    if (this.lives > 0) {
      loseLiveRequest(this.contactId, this.lives, this.setGameMode);
    } else {
      if (this.vitamins.length) {
        this.prize = getRandomPrize(this.vitamins);
      }
      updatePointsPrize(
        this.contactId,
        this.points,
        this.prize,
        IN_GAME_EGOI.FINNISH_PLAYING,
        this.setGameMode
      );
    }
  }

  gameModeStart() {
    this.gamemode = GAMEMODE.START;
    this.lastSavedTime = this.progressTimeSeconds;
  }

  gameModeEnd() {
    this.lastSavedTime = this.progressTimeSeconds;
    this.setLoginInfo({
      ...this.loginInfo,
      points: this.points,
      lives: this.lives,
      prize: this.prize,
      inGame: IN_GAME_EGOI.FINNISH_PLAYING,
    });
    this.setGameScreenState(GAME_SCREEN_STATE.END_GAME);
    this.gamemode = GAMEMODE.END;
  }

  resetGame() {
    this.lives--;
    this.lastSavedTime = 0;
    this.pacman.resetMovingSprite();
    this.blinky.reset();
    this.pinky.reset();
    this.inky.reset();
    this.clyde.reset();
    this.pinky.freeze();
    this.inky.freeze();
    this.clyde.freeze();
  }

  pelletCollision(ctx) {
    let colision = false;
    const pacmanMazePos = Maze.getMazePosition(this, this.pacman.x, this.pacman.y);
    const pellet = this.pellets[pacmanMazePos.y][pacmanMazePos.x];

    if (pellet && pellet.visible) {
      const bbox = boundingBoxDetection(
        this.pacman.x,
        this.pacman.y + this.gameStartY,
        pellet.x,
        pellet.y + this.gameStartY,
        this.unitSize,
        0.1 * this.unitSize * 2
      );

      if (bbox) {
        if (pixelColision(this.pacman, pellet, bbox, ctx)) {
          pellet.visible = false;
          colision = true;
        }
      }
    }

    return colision;
  }

  powerPelletCollision(ctx) {
    let colision = false;
    this.powerPellets.forEach((pellet) => {
      if (pellet && pellet.visible) {
        const bbox = boundingBoxDetection(
          this.pacman.x,
          this.pacman.y + this.gameStartY,
          pellet.x,
          pellet.y + this.gameStartY,
          this.unitSize,
          0.1 * this.unitSize * 2
        );

        if (bbox) {
          if (pixelColision(this.pacman, pellet, bbox, ctx)) {
            pellet.visible = false;
            colision = true;
          }
        }
      }
    });

    return colision;
  }

  ghostCollision(ghost, ctx) {
    const bbox = boundingBoxDetection(
      this.pacman.x,
      this.pacman.y + this.gameStartY,
      ghost.x,
      ghost.y + this.gameStartY,
      this.unitSize,
      this.unitSize
    );

    if (bbox) {
      if (pixelColision(this.pacman, ghost, bbox, ctx)) {
        return true;
      }
    } else return false;
  }

  ghostsCollision(ctx) {
    const colisions = [];

    if (this.ghostCollision(this.blinky, ctx)) {
      colisions.push(this.blinky);
    }

    if (this.ghostCollision(this.pinky, ctx)) {
      colisions.push(this.pinky);
    }

    if (this.ghostCollision(this.inky, ctx)) {
      colisions.push(this.inky);
    }

    if (this.ghostCollision(this.clyde, ctx)) {
      colisions.push(this.clyde);
    }

    return colisions;
  }

  update(progress, ctx) {
    const progressTimeSeconds = Math.floor(progress / 1000);
    this.progressTimeSeconds = progressTimeSeconds;

    if (this.gamemode === GAMEMODE.END || this.gamemode === GAMEMODE.START) {
      return;
    }

    if (this.gamemode === GAMEMODE.RUNNING) {
      if (this.progressTimeSeconds - this.lastSavedTime === 5) {
        // this.lastSavedTime = this.progressTimeSeconds;
        this.pinky.unfreeze();
      }

      if (this.progressTimeSeconds - this.lastSavedTime === 7) {
        // this.lastSavedTime = this.progressTimeSeconds;
        this.inky.unfreeze();
      }

      if (this.progressTimeSeconds - this.lastSavedTime === 7) {
        // this.lastSavedTime = this.progressTimeSeconds;
        this.clyde.unfreeze();
      }

      if (this.pelletCollision(ctx)) {
        this.points += PELLET_POINTS;
        this.activePelletsNum--;
      }

      if (this.powerPelletCollision(ctx)) {
        this.points += POWER_PELLET_POINTS;
        this.activePelletsNum--;

        if (!this.blinky.freezePos) {
          this.blinky.frightnedMode(this.progressTimeSeconds);
        }

        if (!this.pinky.freezePos) {
          this.pinky.frightnedMode(this.progressTimeSeconds);
        }

        if (!this.inky.freezePos) {
          this.inky.frightnedMode(this.progressTimeSeconds);
        }

        if (!this.clyde.freezePos) {
          this.clyde.frightnedMode(this.progressTimeSeconds);
        }
      }

      if (this.activePelletsNum === 0) {
        updatePointsPrize(
          this.contactId,
          this.points,
          this.prize,
          IN_GAME_EGOI.FINNISH_PLAYING,
          this.setGameMode
        );
        this.gameModeEnd();
      }

      const ghostsColided = this.ghostsCollision(ctx);
      if (ghostsColided.length > 0) {
        ghostsColided.forEach((ghost) => {
          if (ghost.isScared()) {
            ghost.goingToBaseMode(this.progressTimeSeconds);
            this.points += GHOST_POINTS;
            if (ghost.vitamin) {
              this.vitamins.push(ghost.vitamin);
            }
          } else if (!ghost.isGoingHome()) {
            this.gameModeDie();
          }
        });
      }

      this.pacman.update();
      this.pinky.update(progress);
      this.inky.update(progress);
      this.clyde.update(progress);
      this.blinky.update(progress);
    }

    if (this.gamemode === GAMEMODE.DIE) {
      if (this.progressTimeSeconds - this.lastSavedTime === 1) {
        this.pacman.die();
      }

      if (this.progressTimeSeconds - this.lastSavedTime >= 1) {
        this.pacman.update();
      }

      if (this.progressTimeSeconds - this.lastSavedTime >= 4) {
        if (this.lives > 0) {
          this.resetGame();
          this.gameModeReady();
        } else {
          this.gameModeEnd();
        }
      }
    }

    if (this.gamemode === GAMEMODE.INTRO && this.progressTimeSeconds - this.lastSavedTime === 20) {
      this.gameModeStart();
    }
  }

  draw(ctx, progress) {
    const progressTimeSeconds = Math.floor(progress / 1000);

    ctx.rect(0, 0, this.gameWidth, this.gameHeight);
    ctx.fillStyle = 'rgba(16, 18, 43, 1)';
    ctx.fill();

    ctx.font = '15px pressStartFont';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'left';

    if (this.gamemode === GAMEMODE.END) {
      endScreen(ctx, progressTimeSeconds, this);
      return;
    }

    ctx.fillText('1UP', 20 + 100, 20 + 5);
    ctx.fillText('PONTUACAO MAXIMA', 250, 20 + 5);

    ctx.fillText(this.points, 20 + 130, 20 + 30);

    if (this.gamemode === GAMEMODE.INTRO) {
      introScreen(ctx, progressTimeSeconds, this);
      return;
    }

    if (this.gamemode === GAMEMODE.START) {
      startScreen(ctx, progressTimeSeconds, this);
      return;
    }

    if (this.gamemode === GAMEMODE.READY) {
      ctx.fillStyle = 'yellow';
      ctx.fillText('Pronto!', 230, 435);

      if (progressTimeSeconds - this.lastSavedTime === 2) {
        this.gamemode = GAMEMODE.RUNNING;
        this.lastSavedTime = progressTimeSeconds;
      }
    }

    this.maze.draw(ctx);
    this.pacman.draw(ctx);
    if (this.gamemode !== GAMEMODE.DIE) {
      this.pellets.forEach((row, ri) =>
        row.forEach((column, ci) => {
          if (this.pellets[ri][ci] && this.pellets[ri][ci].visible) {
            this.pellets[ri][ci].draw(ctx);
          }
        })
      );
      this.powerPellets.forEach((powerPellet) =>
        powerPellet.visible ? powerPellet.draw(ctx) : null
      );

      this.blinky.draw(ctx);
      this.pinky.draw(ctx);
      this.inky.draw(ctx);
      this.clyde.draw(ctx);
    }

    const pacmanImage = document.getElementById('pacman');

    for (let i = 0; i < this.lives; i++) {
      ctx.drawImage(pacmanImage, 0, 0, 15, 15, 30 + 40 * i, 700, 30, 30);
    }
  }
}

export default Game;
