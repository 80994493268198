import { wallSymbols, otherSymbols } from './MazeUtils';

class Wall {
  constructor(lineWidth, unitSize, gameStartY, x, y, type) {
    this.lineWidth = lineWidth;
    this.unitSize = unitSize;
    this.x = x * unitSize;
    this.y = y * unitSize + gameStartY;
    this.type = type;
  }

  draw(ctx) {
    ctx.lineWidth = this.lineWidth.toString();
    ctx.strokeStyle = '#0909A6';
    ctx.beginPath();

    switch (this.type) {
      case wallSymbols.LW:
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y);
        ctx.lineTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y + this.unitSize);
        break;
      case wallSymbols.TW:
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        break;
      case wallSymbols.FF:
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 10,
          this.x + this.unitSize,
          this.y + this.unitSize - (this.lineWidth / 2) * 10
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        break;
      case wallSymbols.BW:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 10);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - (this.lineWidth / 2) * 10);
        break;
      case wallSymbols.DD:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y
        );
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 10);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 10,
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y
        );
        break;
      case wallSymbols.RW:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y);
        ctx.lineTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y + this.unitSize);
        break;
      case wallSymbols.SS:
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.unitSize
        );
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 5,
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize
        );
        break;
      case wallSymbols.AA:
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 5,
          this.x + this.unitSize,
          this.y + this.unitSize - (this.lineWidth / 2) * 5
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.OO:
        ctx.moveTo(this.x + this.unitSize, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5
        );
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 5,
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5
        );
        ctx.moveTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.LL:
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y + this.unitSize);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10,
          this.y + this.unitSize - (this.lineWidth / 2) * 10,
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10
        );
        ctx.moveTo(this.x + this.unitSize, this.y + this.unitSize - (this.lineWidth / 2) * 10);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.lineTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2,
          this.y + this.lineWidth / 2
        );
        break;
      case wallSymbols.UU:
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 5,
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2
        );
        break;
      case wallSymbols.II:
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 10);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 10,
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y + this.unitSize);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - (this.lineWidth / 2) * 5 - this.lineWidth / 2,
          this.y + this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - (this.lineWidth / 2) * 10 + this.lineWidth / 2
        );
        break;
      case wallSymbols.CA:
        ctx.moveTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        break;
      case wallSymbols.CB:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CC:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        break;
      case wallSymbols.CD:
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.lineTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CE:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        break;
      case wallSymbols.CF:
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CG:
        ctx.moveTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CH:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.lineTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CI:
        ctx.moveTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        break;
      case wallSymbols.CJ:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        break;
      case wallSymbols.CK:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.CL:
        ctx.moveTo(this.x + this.lineWidth, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth, this.y + this.unitSize - this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y);
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        break;
      case wallSymbols.CM:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        break;
      case wallSymbols.CN:
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.CO:
        ctx.moveTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize
        );
        break;
      case wallSymbols.CP:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.lineTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize - this.lineWidth);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        break;
      case wallSymbols.CQ:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.unitSize - this.lineWidth, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.lineWidth);
        ctx.lineTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y + this.unitSize
        );
        break;
      case wallSymbols.CR:
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.lineTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth
        );
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(
          this.x + this.unitSize - this.lineWidth,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.lineTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.lineWidth / 2,
          this.y
        );
        break;
      case wallSymbols.CS:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.CT:
        ctx.moveTo(this.x, this.y + this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.lineWidth / 2);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.CU:
        ctx.moveTo(this.x, this.y + this.unitSize - this.lineWidth / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - this.lineWidth / 2);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        break;
      case wallSymbols.CV:
        ctx.moveTo(this.x, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize - (this.lineWidth / 2) * 5);
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        break;
      case wallSymbols.CX:
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.unitSize - this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x + this.unitSize,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y);
        ctx.lineTo(this.x + this.unitSize - (this.lineWidth / 2) * 5, this.y + this.unitSize);
        break;
      case wallSymbols.CZ:
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.lineWidth / 2,
          this.x,
          this.y + this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y + this.unitSize);
        ctx.quadraticCurveTo(
          this.x + this.lineWidth / 2,
          this.y + this.unitSize - this.lineWidth / 2,
          this.x,
          this.y + this.unitSize - this.lineWidth / 2
        );
        ctx.moveTo(this.x + this.lineWidth / 2, this.y);
        ctx.moveTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y);
        ctx.lineTo(this.x + this.unitSize - (this.lineWidth / 2) * 10, this.y + this.unitSize);
        break;
      case otherSymbols.GD:
        ctx.strokeStyle = '#ffffff';
        ctx.moveTo(this.x, this.y + this.unitSize / 2);
        ctx.lineTo(this.x + this.unitSize, this.y + this.unitSize / 2);
        break;
      default:
        break;
    }

    ctx.stroke();
    ctx.closePath();
  }
}

export default Wall;
