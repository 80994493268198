import React from 'react';
import { ARCADE_SHARE } from '../../common/constants';

import facebookPNG from '../../assets/Facebook.svg';
import linkedinPNG from '../../assets/Linkedin.svg';

import './arcade-share.css';

export const facebookClick = () => {
  window.open(
    'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvitman.pt',
    'facebook-share-dialog',
    'width=626,height=436'
  );
  return false;
};

export const linkedinClick = () => {
  var url = 'https://vitman.pt';

  window.open(
    'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url),
    '',
    'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
  );
  return false;
};

const ArcadeShare = () => {
  return (
    <div id="share">
      <svg viewBox="0 0 1356.98 40.89">
        <text x="45%" y="55%" dominantBaseline="middle" textAnchor="middle" className="text">
          {ARCADE_SHARE.SHARE_WITH_FRIENDS}
        </text>
      </svg>
      <a id="facebook-ico" onClick={facebookClick} target="_blank" rel="noreferrer">
        <img src={facebookPNG} alt="helio mkt" />
      </a>
      <a id="linkedin-ico" onClick={linkedinClick} target="_blank" rel="noreferrer">
        <img src={linkedinPNG} alt="helio mkt" />
      </a>
    </div>
  );
};

export default ArcadeShare;
