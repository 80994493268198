/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import './about-screen.css';

const AboutScreen = () => (
  <div id="info-screen">
    <div id="scrollable-text">
      <p>
        Comer, comer, comer… Fugir e ganhar.
        <br /> Apanha as Vitaminas de Marketing que vão alavancar o teu negócio!
      </p>
      <br />
      <p>
        Controlando o <strong className="strong-yellow">VIT-MAN</strong>, o objetivo é comer todas
        as bolas que se encontram distribuídas pelo mercado, enquanto evitas os quatro fantasmas
        'concorrência', 'crise', 'medo' e 'mindset', sempre prontos para fragilizar o teu negócio.
      </p>
      <br />
      <p>
        <strong className="strong-yellow">Como vencer os inimigos do teu negócio?</strong>
        <br /> Pela área de jogo vão aparecer umas bolas maiores (representam a força, mudança,
        agilidade, flexibilidade e adaptação💪) que permitem ao VIT-MAN caçar os fantasmas em vez de
        ser caçado, mas cuidado, apenas durante um curto período de tempo.
      </p>
      <br />
      <p>
        <strong className="strong-yellow">
          A particularidade do jogo ao apanhar as bolas maiores, é que os quatro fantasmas se
          transformam nas vitaminas de marketing. Nesse momento os prémios estão à distância de
          perícia, agilidade e claro, alguma sorte.
        </strong>
      </p>
      <br />
      <p>
        Começas com <strong className="strong-yellow">3 vidas</strong>, por isso dá o teu melhor.
        Porque enquanto jogas não será possível ganhar vidas extras. Mas como o VIT-MAN é teu amigo
        e quer muito que ganhes um prémio, após terminares de jogar existe a oportunidade de ganhar
        uma terceira vida, caso partilhes o jogo com um amigo. Nesse momento, recebes por uma “vida
        extra” e assim a possibilidade de tentar a sorte novamente
      </p>
      <br />
      <p>
        <strong className="strong-red">IMPRÓPRIO PARA TELEMÓVEIS</strong>
        <br />
        Para tornar a experiência o mais genuína possível, vais voltar atrás no tempo. Acede ao
        VIT-MAN através do teu computador e <strong className="strong-yellow">joga</strong>. Podes
        até não ganhar nada, mas promete ser uma experiência viciante e divertida.
      </p>
    </div>
  </div>
);

export default AboutScreen;
