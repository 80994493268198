import React from 'react';
import PropTypes from 'prop-types';
import { GAME_SCREEN } from '../../../../common/constants';

import './share.css';
import { IN_GAME_EGOI } from '../../../../game/js/Game';
import { getShareEmail } from './ShareEmail';

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://vitman.pt/';

const SEND_EMAIL = 'egoi/mailer/send';

const UPDATE_PATH = 'egoi/update';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const Share = ({ setGameScreenState, contactId, firstName }) => {
  const updatePointsPrize = (contactId, lives, inGame) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      contactId,
      lives,
      inGame,
    };

    fetch(`${BASE_URL}${UPDATE_PATH}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw { status: res.status, message: res.statusText };
        }
      })
      .then(() => {})
      .catch(() => {});
  };

  const sendEmail = (html, destinationEmail) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      html,
      destinationEmail,
    };

    fetch(`${BASE_URL}${SEND_EMAIL}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw { status: res.status, message: res.statusText };
        } else return res.json();
      })
      .then(() => {
        setGameScreenState('INTRO');
      })
      .catch(() => {
        setGameScreenState('SHARING_ERROR');
      });
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    const email = evt.target.email.value;

    setGameScreenState('SHARING');

    updatePointsPrize(contactId, 1, IN_GAME_EGOI.SHARED_GAME);

    setTimeout(() => sendEmail(getShareEmail(firstName), email), 1500);
  };

  return (
    <svg id="login" viewBox="0 0 1356.98 449.89">
      <text x="50%" y="20%" {...baseSVGTextProps} className="text yellow">
        INSERE O E-MAIL
      </text>
      <foreignObject x="25%" y="40%">
        <form onSubmit={onSubmit}>
          <input
            className="login-input"
            type="email"
            placeholder={GAME_SCREEN.LOGIN.EMAIL}
            name="email"
            required
          />
          <div id="policy-wrapper">
            <input
              className="login-input checkbox"
              type="checkbox"
              name="policy"
              id="policy"
              required
            />
            <label htmlFor="policy">{GAME_SCREEN.LOGIN.POLICY}</label>
          </div>
          <input id="submit-btn" type="submit" value="PARTILHAR" />
        </form>
      </foreignObject>
    </svg>
  );
};

Share.propTypes = {
  setGameScreenState: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  setLoginInfo: PropTypes.func.isRequired,
  firstName: PropTypes.string,
};

export default Share;
