import React from 'react';

import './rules-screen.css';

const RulesScreen = () => (
  <div id="info-screen">
    <div id="scrollable-text">
      <p>
        <strong className="strong-yellow">PARA JOGAR É PRECISO FAZER O REGISTO?</strong>
      </p>
      <br />
      <p>
        Sim, é mesmo obrigatório, caso contrário não é possível jogar.
        <br />O registo serve para associar o prémio ao jogador e no caso de ganhar, ser notificado
        por e-mail do prémio que ganhou.
        <br />
        Ao mesmo tempo a pontuação também fica registada, pois os prémios do VIT-MAN podem não ficar
        por aqui!
      </p>
      <br />
      <p>
        <strong className="strong-yellow">Mas atenção: </strong>depois de jogar não apagues o
        registo ou faças “unsubscribe” à newsletter. O VIT-MAN é um jogo que tem tanto de dinâmico
        como de imprevisível.
      </p>
      <br />
      <p>
        A qualquer momento podes ser surpreendido com nova possibilidade de jogar, um novo nível que
        foi lançado, novos prémios ou ver a tua pontuação selecionada para ganhar um prémio
        surpresa. E não te preocupes, também não gostamos de spam!
      </p>
    </div>
  </div>
);

export default RulesScreen;
