class Sprite {
  constructor(width, height, image, options, numberOfFrames, ticksPerFrame) {
    (this.width = width), (this.height = height), (this.image = image);
    this.options = options;
    this.numberOfFrames = numberOfFrames;
    this.tickePerFrame = ticksPerFrame;
    this.frameIndex = 0;
    this.tickCount = 0;
  }

  resetFrameIndex() {
    this.frameIndex = 0;
  }

  update() {
    this.tickCount++;

    if (this.tickCount > this.tickePerFrame) {
      this.tickCount = 0;

      if (this.frameIndex === this.numberOfFrames - 1) {
        this.frameIndex = 0;
      } else {
        this.frameIndex++;
      }
    }
  }

  draw(x, y, context) {
    context.drawImage(
      this.image,
      this.options[this.frameIndex].sx,
      this.options[this.frameIndex].sy,
      this.options[this.frameIndex].sw,
      this.options[this.frameIndex].sh,
      x,
      y,
      this.width,
      this.height
    );
  }
}

export default Sprite;
