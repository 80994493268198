import React from 'react';
import PropTypes from 'prop-types';

import { PRE_REGISTER_SCREEN } from '../../../../common/constants';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const RegisterForm = ({ registerFormSubmit }) => (
  <>
    <text x="50%" y="10%" {...baseSVGTextProps} className="text yellow">
      {PRE_REGISTER_SCREEN.REGISTER_FORM.FILL}
    </text>
    <foreignObject x="25%" y="30%">
      <form onSubmit={registerFormSubmit}>
        <input
          className="pre-register-input"
          type="text"
          placeholder={PRE_REGISTER_SCREEN.REGISTER_FORM.FIRST_NAME}
          name="firstName"
          required
        />
        <input
          className="pre-register-input"
          type="text"
          placeholder={PRE_REGISTER_SCREEN.REGISTER_FORM.LAST_NAME}
          name="secondName"
          required
        />
        <input
          className="pre-register-input"
          type="email"
          placeholder={PRE_REGISTER_SCREEN.REGISTER_FORM.EMAIL}
          name="email"
          required
        />
        <input id="submit-btn" type="submit" value={PRE_REGISTER_SCREEN.REGISTER_FORM.REGISTER} />
      </form>
    </foreignObject>
  </>
);

RegisterForm.propTypes = {
  registerFormSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
