import React from 'react';

import './conditions-screen.css';

const email = 'hello@vitman.pt';

const whatsAppLink =
  'https://wa.me/message/DKVNEX6H4QEQB1?utm_source=website&utm_medium=vitman&utm_campaign=whatsapp';

const anchorLinkBaseProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const ConditionsScreen = () => (
  <div id="conditions-screen">
    <div id="scrollable-text">
      <p className="title strong-yellow">Condições e Validade dos Prémios</p>
      <br />
      <p>
        Este VIT-MAN promete dar muitos prémios.
        <br /> Começas com 3 vidas, por isso dá o teu melhor. Enquanto jogas não será possível
        ganhar vidas extras.
        <br /> Mas como o VIT-MAN é teu amigo e quer muito que ganhes um prémio, após terminares de
        jogar vais receber um e-mail com a tua pontuação e também a{' '}
        <span className="strong-yellow">oportunidade de ganhares 1 vida extra</span>, caso{' '}
        <span className="strong-yellow">partilhes o jogo com um amigo.</span>
      </p>
      <br />
      <p>
        <span className="strong-red">Mas atenção:</span>
        <span className="strong-yellow">
          a possibilidade de ganhar prémios nem sempre está ativa. Tens de estar atento ao teu
          e-mail ou às redes sociais para saber quando o VIT-MAN além de um jogo divertido, podes
          ganhar prémios. E quando está ativa, é por um período de 15 dias.
        </span>
      </p>
      <br />
      <p>
        Se foste contemplado, os parabéns de toda a equipa 😊
        <br /> Todos os prémios têm uma data de validade de{' '}
        <span className="strong-yellow">1 mês</span>, a partir do momento que recebes o e-mail.
        <br /> Para usufruir do prémio, basta entrar em contacto connosco durante esse período
        através dos seguintes contactos:
      </p>
      <br />
      <p>
        E-mail:{' '}
        <a className="email-link" href={`mailto:${email}`} {...anchorLinkBaseProps}>
          hello@vitman.pt
        </a>
        <br /> <span className="green">WhatsApp</span>{' '}
        <a className="whatsapp-link" href={whatsAppLink} {...anchorLinkBaseProps}>
          +351 918 261 006
        </a>
      </p>
      <br />
      <p>
        <span className="strong-yellow">Ganhaste um prémio que não se adequa ao teu negócio?</span>
        <br /> Não tem qualquer problema, pois podes oferecer a quem consideres que possa ser útil.
        Apenas precisamos de ser informados dessa mudança, mantendo-se todas as condições descritas
        anteriormente.
      </p>
    </div>
  </div>
);

export default ConditionsScreen;
