import MovingSprite from './MovingSprite';
import { inputKeys } from './inputKeys';
import Sprite from './Sprite';
import {
  pacmanUp,
  pacmanDown,
  pacmanRight,
  pacmanLeft,
  dyingPacOptions,
} from './spriteSheetPoints';
import InputHandler from './input';

const SpriteFrameSpeed = 10;
const pacmanImage = document.getElementById('pacman');
const dyingpac = document.getElementById('dyingpac');

class Pacman extends MovingSprite {
  constructor(game, x, y, velocity, direction) {
    super(game, x, y, velocity, direction, {
      [inputKeys.Up]: new Sprite(
        game.unitSize,
        game.unitSize,
        pacmanImage,
        pacmanUp,
        pacmanUp.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Down]: new Sprite(
        game.unitSize,
        game.unitSize,
        pacmanImage,
        pacmanDown,
        pacmanDown.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Left]: new Sprite(
        game.unitSize,
        game.unitSize,
        pacmanImage,
        pacmanLeft,
        pacmanLeft.length,
        SpriteFrameSpeed
      ),
      [inputKeys.Right]: new Sprite(
        game.unitSize,
        game.unitSize,
        pacmanImage,
        pacmanRight,
        pacmanRight.length,
        SpriteFrameSpeed
      ),
    });

    this.defaultVelocity = velocity;
    this.defaultSprites = this.sprites;

    this.handler = new InputHandler(game);
  }

  die() {
    this.sprites = {
      static: new Sprite(
        this.game.unitSize,
        this.game.unitSize,
        dyingpac,
        dyingPacOptions,
        dyingPacOptions.length,
        SpriteFrameSpeed
      ),
    };
    this.velocity = 0;
  }

  update() {
    this.updateSprite();
  }
}

export default Pacman;
