import React from 'react';
import PropTypes from 'prop-types';
import CountdownScreenContainer from './CountdownScreen/CountdownScreenContainer';
import ContactsScreen from './ContactsScreen/ContactsScreen';
import AboutScreen from './AboutScreen/AboutScreen';
import RulesScreen from './RulesScreen/RulesScreen';
import PolicyScreen from './PolicyScreen/PolicyScreen';
import PreREgisterScreen from './PreRegisterScreen/PreRegisterScreen';
import GameScreen from './GameScreen/GameScreen';
import PrizesScreen from './PrizesScreen/PrizesScreen';
import ConditionsScreen from './ConditionsScreen/ConditionsScreen';

import { SCREENS } from '../../common/constants';

import './arcade-screen.css';

const countdownScreenProps = {
  timeTillDate: '10 17 2020, 8:00 am',
  timeFormat: 'MM DD YYYY, h:mm a',
};

const ArcadeScreen = ({
  selectedScreen,
  setSelectedScreen,
  gameScreenState,
  setGameScreenState,
}) => (
  <div id="screen">
    {selectedScreen === SCREENS.COUNTDOWN_SCREEN && (
      <CountdownScreenContainer {...countdownScreenProps} setSelectedScreen={setSelectedScreen} />
    )}
    {selectedScreen === SCREENS.CONTACTS_SCREEN && <ContactsScreen />}
    {selectedScreen === SCREENS.ABOUT_SCREEN && <AboutScreen />}
    {selectedScreen === SCREENS.RULES_SCREEN && <RulesScreen />}
    {selectedScreen === SCREENS.POLICY_SCREEN && <PolicyScreen />}
    {selectedScreen === SCREENS.PRIZES_SCREEN && <PrizesScreen />}
    {selectedScreen === SCREENS.CONDITIONS_SCREEN && <ConditionsScreen />}
    {selectedScreen === SCREENS.PRE_REGISTER_SCREEN && (
      <PreREgisterScreen setSelectedScreen={setSelectedScreen} />
    )}
    {selectedScreen === SCREENS.GAME_SCREEN && (
      <GameScreen gameScreenState={gameScreenState} setGameScreenState={setGameScreenState} />
    )}
  </div>
);

ArcadeScreen.propTypes = {
  selectedScreen: PropTypes.string.isRequired,
  setSelectedScreen: PropTypes.func.isRequired,
  gameScreenState: PropTypes.string.isRequired,
  setGameScreenState: PropTypes.func.isRequired,
};

export default ArcadeScreen;
