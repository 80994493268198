export const redRight = [
  {
    sx: 0,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 16,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const redLeft = [
  {
    sx: 32,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 48,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const redUp = [
  {
    sx: 64,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 80,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const redDown = [
  {
    sx: 96,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 112,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const pinkRight = [
  {
    sx: 0,
    sy: 16,
    sw: 14,
    sh: 14,
  },
  {
    sx: 16,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const pinkLeft = [
  {
    sx: 32,
    sy: 16,
    sw: 14,
    sh: 14,
  },
  {
    sx: 48,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const pinkUp = [
  {
    sx: 64,
    sy: 16,
    sw: 14,
    sh: 14,
  },
  {
    sx: 80,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const pinkDown = [
  {
    sx: 96,
    sy: 16,
    sw: 14,
    sh: 14,
  },
  {
    sx: 112,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const blueRight = [
  {
    sx: 0,
    sy: 32,
    sw: 14,
    sh: 14,
  },
  {
    sx: 16,
    sy: 32,
    sw: 14,
    sh: 14,
  },
];

export const blueLeft = [
  {
    sx: 32,
    sy: 32,
    sw: 14,
    sh: 14,
  },
  {
    sx: 48,
    sy: 32,
    sw: 14,
    sh: 14,
  },
];

export const blueUp = [
  {
    sx: 64,
    sy: 32,
    sw: 14,
    sh: 14,
  },
  {
    sx: 80,
    sy: 32,
    sw: 14,
    sh: 14,
  },
];

export const blueDown = [
  {
    sx: 96,
    sy: 32,
    sw: 14,
    sh: 14,
  },
  {
    sx: 112,
    sy: 32,
    sw: 14,
    sh: 14,
  },
];

export const orangeRight = [
  {
    sx: 0,
    sy: 48,
    sw: 14,
    sh: 14,
  },
  {
    sx: 16,
    sy: 48,
    sw: 14,
    sh: 14,
  },
];

export const orangeLeft = [
  {
    sx: 32,
    sy: 48,
    sw: 14,
    sh: 14,
  },
  {
    sx: 48,
    sy: 48,
    sw: 14,
    sh: 14,
  },
];

export const orangeUp = [
  {
    sx: 64,
    sy: 48,
    sw: 14,
    sh: 14,
  },
  {
    sx: 80,
    sy: 48,
    sw: 14,
    sh: 14,
  },
];

export const orangeDown = [
  {
    sx: 96,
    sy: 48,
    sw: 14,
    sh: 14,
  },
  {
    sx: 112,
    sy: 48,
    sw: 14,
    sh: 14,
  },
];

export const frightnedBlue = [
  {
    sx: 128,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 144,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const frightnedWhite = [
  {
    sx: 128,
    sy: 0,
    sw: 14,
    sh: 14,
  },
  {
    sx: 176,
    sy: 0,
    sw: 14,
    sh: 14,
  },
];

export const dyingPacOptions = [
  {
    sx: 0,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 16,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 32,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 48,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 64,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 80,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 96,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 112,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 128,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 144,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 160,
    sy: 0,
    sw: 15,
    sh: 13,
  },
  {
    sx: 175,
    sy: 0,
    sw: 15,
    sh: 13,
  },
];

export const pacmanRight = [
  {
    sx: 0,
    sy: 0,
    sw: 15,
    sh: 15,
  },
  {
    sx: 16,
    sy: 0,
    sw: 15,
    sh: 15,
  },
];

export const pacmanLeft = [
  {
    sx: 0,
    sy: 16,
    sw: 15,
    sh: 15,
  },
  {
    sx: 16,
    sy: 16,
    sw: 15,
    sh: 15,
  },
];

export const pacmanUp = [
  {
    sx: 0,
    sy: 32,
    sw: 15,
    sh: 15,
  },
  {
    sx: 16,
    sy: 32,
    sw: 15,
    sh: 15,
  },
];

export const pacmanDown = [
  {
    sx: 0,
    sy: 48,
    sw: 15,
    sh: 15,
  },
  {
    sx: 16,
    sy: 48,
    sw: 15,
    sh: 15,
  },
];

export const eyesRight = [
  {
    sx: 129,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const eyesLeft = [
  {
    sx: 143,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const eyesUp = [
  {
    sx: 160,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const eyesDown = [
  {
    sx: 176,
    sy: 16,
    sw: 14,
    sh: 14,
  },
];

export const vitamins = [
  {
    sx: 0,
    sy: 0,
    sw: 30,
    sh: 30,
  },
];
