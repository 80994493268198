import Ghost, { GHOST_MODE } from './Ghost';
import { pinkDown, pinkUp, pinkRight, pinkLeft, vitamins } from './spriteSheetPoints';
import { inputKeys } from './inputKeys';
import Sprite from './Sprite';
import Maze from './Maze';
import { isWall } from './MazeUtils';
import matrix from './MazeUtils';

const SpriteFrameSpeed = 7;
const pinkyImage = document.getElementById('ghosts');
const scatterPoints = [
  { x: 1, y: 1 },
  { x: 4, y: 4 },
];
const offlineV = document.getElementById('off_sprite');

class Pinky extends Ghost {
  constructor(game, x, y, velocity, direction) {
    super(
      game,
      x,
      y,
      velocity,
      direction,
      {
        [inputKeys.Right]: new Sprite(
          game.unitSize,
          game.unitSize,
          pinkyImage,
          pinkRight,
          pinkRight.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Left]: new Sprite(
          game.unitSize,
          game.unitSize,
          pinkyImage,
          pinkLeft,
          pinkLeft.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Up]: new Sprite(
          game.unitSize,
          game.unitSize,
          pinkyImage,
          pinkUp,
          pinkUp.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Down]: new Sprite(
          game.unitSize,
          game.unitSize,
          pinkyImage,
          pinkDown,
          pinkDown.length,
          SpriteFrameSpeed
        ),
      },
      scatterPoints
    );

    this.vitaminSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        offlineV,
        vitamins,
        vitamins.length,
        SpriteFrameSpeed
      ),
    };
    this.pointsAhead = 5;
  }

  reset() {
    this.resetGhost();
    this.pointsAhead = 5;
  }

  getAmbushPoint(pointsAhead) {
    this.targetPoint = Maze.getMazePosition(this.game, this.game.pacman.x, this.game.pacman.y);
    let counter = 0;
    switch (this.game.pacman.direction) {
      case inputKeys.Right:
        while (counter < pointsAhead) {
          this.targetPoint.x++;
          counter++;
          if (isWall(matrix[this.targetPoint.y][this.targetPoint.x])) {
            this.targetPoint.x--;
            break;
          }
        }
        break;
      case inputKeys.Left:
        while (counter < pointsAhead) {
          this.targetPoint.x--;
          counter++;
          if (isWall(matrix[this.targetPoint.y][this.targetPoint.x])) {
            this.targetPoint.x++;
            break;
          }
        }

        break;
      case inputKeys.Up:
        while (counter < pointsAhead) {
          this.targetPoint.y--;
          counter++;
          if (isWall(matrix[this.targetPoint.y][this.targetPoint.x])) {
            this.targetPoint.y++;
            break;
          }
        }

        break;
      case inputKeys.Down:
        while (counter < pointsAhead) {
          this.targetPoint.y++;
          counter++;
          if (isWall(matrix[this.targetPoint.y][this.targetPoint.x])) {
            this.targetPoint.y--;
            break;
          }
        }

        break;

      default:
        break;
    }
  }

  update(progress) {
    if (this.ghostmode === GHOST_MODE.CHASE) {
      this.getAmbushPoint(this.pointsAhead);
    }

    this.updateGhost(progress);
  }
}

export default Pinky;
