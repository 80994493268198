import Ghost, { GHOST_MODE } from './Ghost';
import { orangeDown, orangeUp, orangeRight, orangeLeft, vitamins } from './spriteSheetPoints';
import { inputKeys } from './inputKeys';
import Sprite from './Sprite';
import { randomPoint } from './algorithms';

const SpriteFrameSpeed = 7;
const clydeImage = document.getElementById('ghosts');
const scatterPoints = [
  { x: 1, y: 20 },
  { x: 8, y: 20 },
  { x: 5, y: 16 },
];
const criativeV = document.getElementById('criat_sprite');

class Clyde extends Ghost {
  constructor(game, x, y, velocity, direction) {
    super(
      game,
      x,
      y,
      velocity,
      direction,
      {
        [inputKeys.Right]: new Sprite(
          game.unitSize,
          game.unitSize,
          clydeImage,
          orangeRight,
          orangeRight.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Left]: new Sprite(
          game.unitSize,
          game.unitSize,
          clydeImage,
          orangeLeft,
          orangeLeft.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Up]: new Sprite(
          game.unitSize,
          game.unitSize,
          clydeImage,
          orangeUp,
          orangeUp.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Down]: new Sprite(
          game.unitSize,
          game.unitSize,
          clydeImage,
          orangeDown,
          orangeDown.length,
          SpriteFrameSpeed
        ),
      },
      scatterPoints
    );

    this.vitaminSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        criativeV,
        vitamins,
        vitamins.length,
        SpriteFrameSpeed
      ),
    };
    this.targetPoint = randomPoint();
  }

  reset() {
    this.resetGhost();
  }

  update(progress) {
    if (
      this.ghostmode === GHOST_MODE.CHASE &&
      this.onTargetPoint(this.targetPoint.x, this.targetPoint.y)
    ) {
      this.targetPoint = randomPoint();
    }
    this.updateGhost(progress);
  }
}

export default Clyde;
