import Ghost, { GHOST_MODE } from './Ghost';
import { redDown, redUp, redRight, redLeft, vitamins } from './spriteSheetPoints';
import { inputKeys } from './inputKeys';
import Sprite from './Sprite';
import Maze from './Maze';

const SpriteFrameSpeed = 7;
const blinkyImage = document.getElementById('ghosts');
const scatterPoints = [
  { x: 14, y: 1 },
  { x: 17, y: 4 },
];

const tecnologyV = document.getElementById('tec_sprite');

class Blinky extends Ghost {
  constructor(game, x, y, velocity, direction) {
    super(
      game,
      x,
      y,
      velocity,
      direction,
      {
        [inputKeys.Right]: new Sprite(
          game.unitSize,
          game.unitSize,
          blinkyImage,
          redRight,
          redRight.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Left]: new Sprite(
          game.unitSize,
          game.unitSize,
          blinkyImage,
          redLeft,
          redLeft.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Up]: new Sprite(
          game.unitSize,
          game.unitSize,
          blinkyImage,
          redUp,
          redUp.length,
          SpriteFrameSpeed
        ),
        [inputKeys.Down]: new Sprite(
          game.unitSize,
          game.unitSize,
          blinkyImage,
          redDown,
          redDown.length,
          SpriteFrameSpeed
        ),
      },
      scatterPoints
    );

    this.vitaminSprites = {
      static: new Sprite(
        game.unitSize,
        game.unitSize,
        tecnologyV,
        vitamins,
        vitamins.length,
        SpriteFrameSpeed
      ),
    };
  }

  reset() {
    this.resetGhost();
  }

  update(progress) {
    if (this.ghostmode === GHOST_MODE.CHASE) {
      this.targetPoint = Maze.getMazePosition(this.game, this.game.pacman.x, this.game.pacman.y);
    }

    this.updateGhost(progress);
  }
}

export default Blinky;
