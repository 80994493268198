export const ARCADE_ITEMS_TYPE = {
  ABOUT: 'ABOUT',
  ARCADE_FRAME: 'ARCADE_FRAME',
  BLUE_GHOST: 'BLUE_GHOST',
  JOYSTICK: 'JOYSTICK',
  MOBILE: 'MOBILE',
  PACMAN: 'PACMAN',
  PINK_GHOST: 'PINK GHOST',
  PLAY: 'PLAY',
  PRIZES: 'PRIZES',
  RULES: 'RULES',
  VITAMIN: 'VITAMIN',
  WHITE_GHOST: 'WHITE_GHOST',
  YELLOW_GHOST: 'YELLOW_GHOST',
  TRADEMARK: 'TRADEMARK',
};

export const COUNTDOWN_SCREEN = {
  IS_ONLINE: 'ESTÁ ONLINE EM:',
  DAYS: 'DIAS',
  HOURS: 'HORAS',
  MINUTES: 'MIN',
  PRE_REGISTER: 'PRÉ-REGISTO',
};

export const CONTACTS_SCREEN = {
  CONTACTS: 'Contactos',
  EMAIL: 'hello@vitman.pt',
  WHATSAPP: 'WhatsApp',
  CELLPHONE_NUM: '918261006',
  FACEBOOK_GROUP: 'Grupo Facebook',
  LINKEDIN_PAGE: 'Página LinkedIn',
};

export const ARCADE_SHARE = {
  SHARE_WITH_FRIENDS: 'partilha com os teus amigos',
};

export const PRE_REGISTER_SCREEN = {
  REGISTER_FORM: {
    FILL: 'PREENCHER PARA JOGAR',
    FIRST_NAME: 'Primeiro Nome',
    LAST_NAME: 'Último Nome',
    EMAIL: 'E-mail',
    REGISTER: 'Registar',
  },
  REGISTER_MESSAGE: {
    SUCCESSFULL: 'REGISTO COM SUCESSO',
    REGISTERING: 'A REGISTAR',
    FAILED_ONE: 'EMAIL JÁ EXISTE',
    FAILED_TWO: 'NA BASE DE DADOS!',
  },
};

export const ARCADE_FOOTER = {
  CONDITIONS: 'Condições e validade dos prémios',
  CONTACTS: 'Contactos',
  POLITICS: 'Política de Privacidade e Cookies',
  POWERED_BY: 'POWERED BY',
};

export const SCREENS = {
  ABOUT_SCREEN: 'ABOUT_SCREEN',
  CONTACTS_SCREEN: 'CONTACTS_SCREEN',
  COUNTDOWN_SCREEN: 'COUNTDOWN_SCREEN',
  POLICY_SCREEN: 'POLICY_SCREEN',
  PRE_REGISTER_SCREEN: 'PRE_REGISTER_SCREEN',
  RULES_SCREEN: 'RULES_SCREEN',
  GAME_SCREEN: 'GAME_SCREEN',
  PRIZES_SCREEN: 'PRIZES_SCREEN',
  CONDITIONS_SCREEN: 'CONDITIONS_SCREEN',
};

export const REGISTER_STATE = {
  PRE_REGISTER: 'PRE_REGISTER',
  SUCCESSFULL: 'SUCCESSFULL',
  REGISTERING: 'REGISTERING',
  FAILED: 'FAILED',
};

export const GAME_SCREEN_STATE = {
  INTRO: 'INTRO',
  LOGIN: 'LOGIN',
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_ERROR_INVALID_LOGIN: 'INVALID_LOGIN',
  LOGIN_ERROR_NO_LIVES: 'NO_LIVES',
  LOGIN_ERROR_BASE: 'BASE',
  KEYS_PICKER: 'KEYS_PICKER',
  PLAY: 'PLAY',
  END_GAME: 'END_GAME',
  SHARE_GAME: 'SHARE_GAME',
};

export const GAME_SCREEN = {
  INTRO: {
    EAT: 'Comer, comer, comer...',
    RUN: 'fugir e ganhar.',
    CATCH: 'Apanha as Vitaminas do Marketing',
    BUSINESS: 'que vão alavancar o teu negócio.',
    PLAY: 'Quero Jogar',
  },
  LOGIN: {
    FILL: 'PREENCHER PARA JOGAR',
    FIRST_NAME: 'Primeiro Nome',
    LAST_NAME: 'Último Nome',
    EMAIL: 'E-mail',
    POLICY: 'Li e Aceito a Política de Privacidade e Cookies.',
    REGISTER: 'Jogar',
  },
  KEYS_INFO: {
    KEYS: 'AS TECLAS DO JOGO SÃO:',
    UP: 'Cima',
    DOWN: 'Baixo',
    LEFT: 'Esquerda',
    RIGHT: 'Right',
    SKIP_INTRO: 'Avançar a Introdução',
    CONTINUE: 'Continuar para o jogo',
  },
  LOGGING_IN: 'A fazer o Login!',
  LOGIN_ERROR: {
    ERROR: 'O Login não foi feito com sucesso!',
    TRY: 'Por favor tente de novo.',
  },
};
