import React, { useState } from 'react';
import ArcadeItem from './components/ArcadeItem/ArcadeItem';
import ArcadeScreen from './components/ArcadeScreen/ArcadeScreen';
import ArcadeShare from './components/ArcadeShare/ArcadeShare';
import ArcadeFooter from './components/ArcadeFooter/ArcadeFooter';
import Mobile from './components/Mobile/Mobile';

import { ARCADE_ITEMS_TYPE, GAME_SCREEN_STATE, SCREENS } from './common/constants';

import './styles.css';

const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

const newTime = () => {
  var now = new Date();
  var end = new Date('October 17, 2020 08:00:00');

  const timeDiff = end.getTime() - now.getTime();

  if (timeDiff < 0) {
    return SCREENS.GAME_SCREEN;
  }

  return SCREENS.COUNTDOWN_SCREEN;
};

const Arcade = () => {
  const [selectedScreen, setSelectedScreen] = useState(newTime());
  const [arcadeFrameLoaded, setArcadeFrameLoaded] = useState(false);
  const [gameScreenState, setGameScreenState] = useState(GAME_SCREEN_STATE.INTRO);

  const blockedState = () => {
    return (
      gameScreenState === GAME_SCREEN_STATE.KEYS_PICKER ||
      gameScreenState === GAME_SCREEN_STATE.PLAY ||
      gameScreenState === GAME_SCREEN_STATE.LOGGING_IN
    );
  };

  return (
    <>
      <div id="arcade-machine">
        {isMobile() ? (
          <Mobile />
        ) : (
          <>
            <ArcadeItem
              setLoaded={setArcadeFrameLoaded}
              type={ARCADE_ITEMS_TYPE.ARCADE_FRAME}
              setSelectedScreen={setSelectedScreen}
            />
            {arcadeFrameLoaded && (
              <>
                <ArcadeScreen
                  gameScreenState={gameScreenState}
                  setGameScreenState={setGameScreenState}
                  selectedScreen={selectedScreen}
                  setSelectedScreen={setSelectedScreen}
                />
                <ArcadeItem
                  type={ARCADE_ITEMS_TYPE.ABOUT}
                  setSelectedScreen={setSelectedScreen}
                  disabled={selectedScreen === SCREENS.GAME_SCREEN && blockedState()}
                />
                <ArcadeItem
                  type={ARCADE_ITEMS_TYPE.RULES}
                  setSelectedScreen={setSelectedScreen}
                  disabled={selectedScreen === SCREENS.GAME_SCREEN && blockedState()}
                />
                <ArcadeItem
                  type={ARCADE_ITEMS_TYPE.PRIZES}
                  setSelectedScreen={setSelectedScreen}
                  disabled={selectedScreen === SCREENS.GAME_SCREEN && blockedState()}
                />
                <ArcadeItem
                  type={ARCADE_ITEMS_TYPE.PLAY}
                  setSelectedScreen={setSelectedScreen}
                  setGameScreenState={setGameScreenState}
                  disabled={selectedScreen === SCREENS.GAME_SCREEN && blockedState()}
                />
                <ArcadeItem type={ARCADE_ITEMS_TYPE.JOYSTICK} />
                <ArcadeItem type={ARCADE_ITEMS_TYPE.TRADEMARK} />
                <ArcadeShare />
                <ArcadeFooter
                  disabled={selectedScreen === SCREENS.GAME_SCREEN && blockedState()}
                  setSelectedScreen={setSelectedScreen}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Arcade;
