import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Intro from './Intro/Intro';
import Login from './Login/Login';
import KeysInfo from './KeysInfo/KeysInfo';
import Canvas from '../../../game/Canvas';

import { GAME_SCREEN_STATE } from '../../../common/constants';

import './game-screen.css';
import LoginMessage from './LoginMessage/LoginMessage';
import EndGame from './EndGame/EndGame';
import Share from './Share/Share';

const GameScreen = ({ gameScreenState, setGameScreenState }) => {
  const [loginInfo, setLoginInfo] = useState({
    contactId: null,
    firstName: null,
    lastName: null,
    email: null,
    lives: null,
    points: null,
    prize: null,
    inGame: null,
    previousPlayed: null,
  });

  return (
    <div id="game-screen">
      {gameScreenState === GAME_SCREEN_STATE.INTRO && (
        <Intro setGameScreenState={setGameScreenState} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.LOGIN && (
        <Login setGameScreenState={setGameScreenState} setLoginInfo={setLoginInfo} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.LOGGING_IN && (
        <LoginMessage gameScreenState={gameScreenState} />
      )}
      {gameScreenState === 'SHARING' && <LoginMessage gameScreenState={gameScreenState} />}
      {gameScreenState === 'SHARING_ERROR' && <LoginMessage gameScreenState={gameScreenState} />}
      {gameScreenState === GAME_SCREEN_STATE.LOGIN_ERROR_BASE && (
        <LoginMessage gameScreenState={gameScreenState} setGameScreenState={setGameScreenState} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.LOGIN_ERROR_INVALID_LOGIN && (
        <LoginMessage gameScreenState={gameScreenState} setGameScreenState={setGameScreenState} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.LOGIN_ERROR_NO_LIVES && (
        <LoginMessage gameScreenState={gameScreenState} setGameScreenState={setGameScreenState} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.KEYS_PICKER && (
        <KeysInfo setGameScreenState={setGameScreenState} />
      )}
      {gameScreenState === GAME_SCREEN_STATE.SHARE_GAME && (
        <Share
          setGameScreenState={setGameScreenState}
          contactId={loginInfo.contactId}
          firstName={loginInfo.firstName}
        />
      )}
      {gameScreenState === GAME_SCREEN_STATE.PLAY && (
        <Canvas
          gameScreenState={gameScreenState}
          setGameScreenState={setGameScreenState}
          loginInfo={loginInfo}
          setLoginInfo={setLoginInfo}
        />
      )}
      {gameScreenState === GAME_SCREEN_STATE.END_GAME && (
        <EndGame
          setGameScreenState={setGameScreenState}
          points={loginInfo.points}
          prize={loginInfo.prize}
          contactId={loginInfo.contactId}
          email={loginInfo.email}
          firstName={loginInfo.firstName}
          lastName={loginInfo.lastName}
          previousPlayed={loginInfo.previousPlayed}
        />
      )}
    </div>
  );
};

GameScreen.propTypes = {
  gameScreenState: PropTypes.string.isRequired,
  setGameScreenState: PropTypes.func.isRequired,
};

export default GameScreen;
