import React from 'react';
import PropTypes from 'prop-types';
import { ARCADE_ITEMS_TYPE, GAME_SCREEN_STATE, SCREENS } from '../../common/constants';

import aboutBtnSVG from '../../assets/aboutButton.svg';
import rulesBtnSVG from '../../assets/rulesButton.svg';
import prizesBtnSVG from '../../assets/prizesButton.svg';
import playBtnSVG from '../../assets/playButton.svg';
import joystickSVG from '../../assets/joystick.svg';
import arcadeSVG from '../../assets/arcade.svg';
import yellowGhostSVG from '../../assets/yellow_ghost.svg';
import whiteGhostSVG from '../../assets/white_ghost.svg';
import blueGhostSVG from '../../assets/blue_ghost.svg';
import pinkGhostSVG from '../../assets/pink_ghost.svg';
import pacmanSVG from '../../assets/pacman.svg';
import VitaminSVG from '../../assets/vitamingreen.svg';

import './arcade-item.css';

const ArcadeItem = ({ type, setSelectedScreen, setLoaded, disabled, setGameScreenState }) => {
  switch (type) {
    case ARCADE_ITEMS_TYPE.ABOUT:
      return (
        <input
          type="image"
          className="arcade-btn"
          id="about-btn"
          src={aboutBtnSVG}
          alt="about button"
          disabled={disabled}
          onClick={() => setSelectedScreen(SCREENS.ABOUT_SCREEN)}
        />
      );
    case ARCADE_ITEMS_TYPE.RULES:
      return (
        <input
          type="image"
          className="arcade-btn"
          id="rules-btn"
          src={rulesBtnSVG}
          alt="rules button"
          disabled={disabled}
          onClick={() => setSelectedScreen(SCREENS.RULES_SCREEN)}
        />
      );
    case ARCADE_ITEMS_TYPE.PRIZES:
      return (
        <input
          type="image"
          className="arcade-btn"
          id="prizes-btn"
          src={prizesBtnSVG}
          alt="play button"
          disabled={disabled}
          onClick={() => setSelectedScreen(SCREENS.PRIZES_SCREEN)}
        />
      );
    case ARCADE_ITEMS_TYPE.PLAY:
      return (
        <input
          type="image"
          className="arcade-btn"
          id="play-btn"
          src={playBtnSVG}
          alt="play button"
          disabled={disabled}
          onClick={() => {
            setGameScreenState(GAME_SCREEN_STATE.INTRO), setSelectedScreen(SCREENS.GAME_SCREEN);
          }}
        />
      );
    case ARCADE_ITEMS_TYPE.JOYSTICK:
      return <img id="joystick" src={joystickSVG} alt="joystick" />;
    case ARCADE_ITEMS_TYPE.ARCADE_FRAME:
      return (
        <img
          type="image"
          id="arcade-machine-img"
          src={arcadeSVG}
          alt="arcade frame"
          onLoad={() => setLoaded(true)}
          onClick={() => setSelectedScreen(SCREENS.GAME_SCREEN)}
        />
      );
    case ARCADE_ITEMS_TYPE.YELLOW_GHOST:
      return <img id="yellow-ghost" src={yellowGhostSVG} alt="yellow ghost" />;
    case ARCADE_ITEMS_TYPE.BLUE_GHOST:
      return <img id="blue-ghost" src={blueGhostSVG} alt="blue ghost" />;
    case ARCADE_ITEMS_TYPE.WHITE_GHOST:
      return <img id="white-ghost" src={whiteGhostSVG} alt="white ghost" />;
    case ARCADE_ITEMS_TYPE.PINK_GHOST:
      return <img id="pink-ghost" src={pinkGhostSVG} alt="pink ghost" />;
    case ARCADE_ITEMS_TYPE.PACMAN:
      return <img id="pacman" src={pacmanSVG} alt="pacman" />;
    case ARCADE_ITEMS_TYPE.VITAMIN:
      return <img id="vitamin" src={VitaminSVG} alt="vitamin" />;
    case ARCADE_ITEMS_TYPE.TRADEMARK:
      // return <span id="trademark">VIT-MAN© 2020 - TODOS OS DIREITOS RESERVADOS</span>;
      return (
        <div id="trademark">
          <svg viewBox="0 0 516.24 37.72">
            <text x="15" y="25">
              VIT-MAN© 2020 - TODOS OS DIREITOS RESERVADOS
            </text>
          </svg>
        </div>
      );
    default:
      return null;
  }
};

ArcadeItem.defaultProps = {
  setSelectedScreen: () => {},
};

ArcadeItem.propTypes = {
  type: PropTypes.string.isRequired,
  setSelectedScreen: PropTypes.func,
  setLoaded: PropTypes.func,
  disabled: PropTypes.bool,
  setGameScreenState: PropTypes.func,
};

export default ArcadeItem;
