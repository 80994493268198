import MazeObject from './MazeObject';

const RADIUS = 0.1;
const COLOR = '#ffffff';

class Pellet extends MazeObject {
  constructor(game, x, y) {
    super(game, x + 0.5, y + 0.5);

    this.visible = true;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y + this.game.gameStartY, RADIUS * this.game.unitSize, 0, 2 * Math.PI);
    ctx.fillStyle = COLOR;
    ctx.strokeStyle = COLOR;
    ctx.fill();
  }
}

export default Pellet;
