import MazeObject from './MazeObject';
import Maze from './Maze';
import { inputKeys } from './inputKeys';

class MovingObject extends MazeObject {
  constructor(game, x, y, velocity, direction) {
    super(game, x, y);

    this.velocity = velocity;
    this.direction = direction;
    this.waitingDirection = direction;
    this.defaultVelocity = velocity;
    this.defaultDirection = direction;
  }

  resetMovingObject() {
    this.resetMazeObject();
    this.velocity = this.defaultVelocity;
    this.direction = this.defaultDirection;
  }

  canChangeDirection() {
    if (this.waitingDirection !== this.direction) {
      switch (this.waitingDirection) {
        case inputKeys.Right:
          return (
            Maze.moveDist(
              this.x + this.velocity,
              this.y,
              this.game.unitSize,
              this.velocity,
              this.waitingDirection
            ).right === this.velocity
          );
        case inputKeys.Left:
          return (
            Maze.moveDist(
              this.x - this.velocity,
              this.y,
              this.game.unitSize,
              this.velocity,
              this.waitingDirection
            ).left === this.velocity
          );
        case inputKeys.Up:
          return (
            Maze.moveDist(
              this.x,
              this.y - this.velocity,
              this.game.unitSize,
              this.velocity,
              this.waitingDirection
            ).up === this.velocity
          );
        case inputKeys.Down:
          return (
            Maze.moveDist(
              this.x,
              this.y + this.velocity,
              this.game.unitSize,
              this.velocity,
              this.waitingDirection
            ).down === this.velocity
          );
        default:
          break;
      }
    }

    return false;
  }

  move() {
    switch (this.direction) {
      case inputKeys.Right:
        this.x += Math.abs(
          Maze.moveDist(
            this.x + this.velocity,
            this.y,
            this.game.unitSize,
            this.velocity,
            this.direction
          ).right
        );
        if (this.x > this.game.gameWidth) this.x = -1 * this.game.unitSize;
        break;
      case inputKeys.Left:
        this.x -= Math.abs(
          Maze.moveDist(
            this.x - this.velocity,
            this.y,
            this.game.unitSize,
            this.velocity,
            this.direction
          ).left
        );

        if (this.x + this.game.unitSize - 1 < 0)
          this.x = this.game.unitSize * (this.game.gameWidth / this.game.unitSize);
        break;
      case inputKeys.Up:
        this.y -= Math.abs(
          Maze.moveDist(
            this.x,
            this.y - this.velocity,
            this.game.unitSize,
            this.velocity,
            this.direction
          ).up
        );
        break;
      case inputKeys.Down:
        this.y += Math.abs(
          Maze.moveDist(
            this.x,
            this.y + this.velocity,
            this.game.unitSize,
            this.velocity,
            this.direction
          ).down
        );
        break;

      default:
        break;
    }
  }
}

export default MovingObject;
