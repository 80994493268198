import React from 'react';
import PropTypes from 'prop-types';

import { PRE_REGISTER_SCREEN, REGISTER_STATE } from '../../../../common/constants';

const baseSVGTextProps = {
  dominantBaseline: 'middle',
  textAnchor: 'middle',
};

const RegisterMessage = ({ registerState }) => {
  switch (registerState) {
    case REGISTER_STATE.SUCCESSFULL:
      return (
        <text x="50%" y="50%" {...baseSVGTextProps} className="text yellow">
          {PRE_REGISTER_SCREEN.REGISTER_MESSAGE.SUCCESSFULL}
        </text>
      );
    case REGISTER_STATE.REGISTERING:
      return (
        <text x="50%" y="50%" {...baseSVGTextProps} className="text yellow">
          <animate
            attributeType="XML"
            attributeName="fill"
            values="#fff;#ffa500;#fff"
            dur="1.5s"
            repeatCount="indefinite"
          />
          {PRE_REGISTER_SCREEN.REGISTER_MESSAGE.REGISTERING}
        </text>
      );
    case REGISTER_STATE.FAILED:
      return (
        <>
          <text x="50%" y="40%" {...baseSVGTextProps} className="text yellow">
            {PRE_REGISTER_SCREEN.REGISTER_MESSAGE.FAILED_ONE}
          </text>
          <text x="50%" y="60%" {...baseSVGTextProps} className="text yellow">
            {PRE_REGISTER_SCREEN.REGISTER_MESSAGE.FAILED_TWO}
          </text>
        </>
      );

    default:
      return null;
  }
};

RegisterMessage.propTypes = {
  registerState: PropTypes.string.isRequired,
};

export default RegisterMessage;
