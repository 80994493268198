import mazeMatrix, { isWall } from './MazeUtils';
import { inputKeys } from './inputKeys';
import Wall from './Wall';
import Pellet from './Pellet';
import PowerPellet from './PowerPellet';

class Maze {
  constructor() {
    this.walls = [];
  }

  static moveDist(x, y, unitSize, velocity, direction) {
    const xi = x;
    const yi = y;
    const xf = x + unitSize - 1;
    const yf = y + unitSize - 1;

    const yixi = isWall(mazeMatrix[Math.trunc(yi / unitSize)][Math.trunc(xi / unitSize)]);
    const yfxf = isWall(mazeMatrix[Math.trunc(yf / unitSize)][Math.trunc(xf / unitSize)]);
    const yfxi = isWall(mazeMatrix[Math.trunc(yf / unitSize)][Math.trunc(xi / unitSize)]);
    const yixf = isWall(mazeMatrix[Math.trunc(yi / unitSize)][Math.trunc(xf / unitSize)]);

    const left =
      (direction === inputKeys.Left && yixi) || (direction === inputKeys.Left && yfxi)
        ? (unitSize * Math.trunc(xi / unitSize) + unitSize - x) * -1 + velocity
        : velocity;
    const up =
      (direction === inputKeys.Up && yixi) || (direction === inputKeys.Up && yixf)
        ? (unitSize * Math.trunc(yi / unitSize) + unitSize - yi) * -1 + velocity
        : velocity;
    const right =
      (direction === inputKeys.Right && yixf) || (direction === inputKeys.Right && yfxf)
        ? xf - unitSize * Math.trunc(xf / unitSize) - velocity + 1
        : velocity;
    const down =
      (direction === inputKeys.Down && yfxi) || (direction === inputKeys.Down && yfxf)
        ? yf - unitSize * Math.trunc(yf / unitSize) - velocity + 1
        : velocity;

    return { left, right, up, down };
  }

  static getMazePosition(game, x, y) {
    return { x: Math.round(x / game.unitSize), y: Math.round(y / game.unitSize) };
  }

  static getPelletsMatrix(game) {
    return mazeMatrix.map((row, ri) => {
      return row.map((column, ci) =>
        mazeMatrix[ri][ci] === '  ' ? new Pellet(game, ci, ri) : null
      );
    });
  }

  static getPowerPellets(game) {
    const powerPellets = [];

    mazeMatrix.forEach((row, ri) => {
      row.forEach((column, ci) =>
        mazeMatrix[ri][ci] === 'PP' ? powerPellets.push(new PowerPellet(game, ci, ri)) : null
      );
    });

    return powerPellets;
  }

  buildMaze(unitSize, lineWidth, gameStartY) {
    mazeMatrix.forEach((row, ri) => {
      row.forEach((column, ci) => {
        this.walls.push(new Wall(lineWidth, unitSize, gameStartY, ci, ri, mazeMatrix[ri][ci]));
      });
    });
  }

  draw(ctx) {
    this.walls.forEach((wall) => {
      wall.draw(ctx);
    });
  }
}

export default Maze;
