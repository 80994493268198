import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArcadeItem from '../../ArcadeItem/ArcadeItem';
import RegisterForm from './RegisterForm/RegisterForm';
import RegisterMessage from './RegisterMessage/RegisterMessage';

import { ARCADE_ITEMS_TYPE, REGISTER_STATE } from '../../../common/constants';

import './pre-register-screen.css';

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://vitman.pt/';

const REGISTER_PATH = 'egoi/register';

const PreRegisterScreen = () => {
  const [registerState, setRegisterState] = useState(REGISTER_STATE.PRE_REGISTER);

  const egoiRequest = (firstName, lastName, email) => {
    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');

    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };

    fetch(`${BASE_URL}${REGISTER_PATH}`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw { status: res.status, message: res.statusText };
        }
      })
      .then(() => {
        setRegisterState(REGISTER_STATE.SUCCESSFULL);
      })
      .catch(() => {
        setRegisterState(REGISTER_STATE.FAILED);
      });
  };

  const registerFormSubmit = (evt) => {
    evt.preventDefault();

    const firstName = evt.target.firstName.value;
    const secondName = evt.target.secondName.value;
    const email = evt.target.email.value;

    setRegisterState(REGISTER_STATE.REGISTERING);

    setTimeout(() => egoiRequest(firstName, secondName, email), 1500);
  };

  return (
    <div id="pre-register-screen">
      <ArcadeItem type={ARCADE_ITEMS_TYPE.VITAMIN} />
      <ArcadeItem type={ARCADE_ITEMS_TYPE.YELLOW_GHOST} />
      <ArcadeItem type={ARCADE_ITEMS_TYPE.PACMAN} />
      <ArcadeItem type={ARCADE_ITEMS_TYPE.WHITE_GHOST} />
      <svg viewBox="0 0 1356.98 449.89">
        {registerState === REGISTER_STATE.PRE_REGISTER && (
          <RegisterForm registerFormSubmit={registerFormSubmit} />
        )}
        {registerState === REGISTER_STATE.SUCCESSFULL && (
          <RegisterMessage registerState={registerState} />
        )}
        {registerState === REGISTER_STATE.REGISTERING && (
          <RegisterMessage registerState={registerState} />
        )}
        {registerState === REGISTER_STATE.FAILED && (
          <RegisterMessage registerState={registerState} />
        )}
      </svg>
    </div>
  );
};

PreRegisterScreen.propTypes = {
  setSelectedScreen: PropTypes.func.isRequired,
};

export default PreRegisterScreen;
