import fetch from 'node-fetch';

export const LIST_NUMBER = 1;

export const egoiApiOptions = {
  server: 'https://api.egoiapp.com/',
  apiKey: 'cce94927e5203535a35a90688d47a3436c896a83',
  paths: {
    register: `lists/${LIST_NUMBER}/contacts`,
    searchContact: 'contacts/search',
    getContact: `lists/${LIST_NUMBER}/contacts/`,
    createCampaign: 'campaigns/email',
    sendEmail: (campaignHash) => `campaigns/email/${campaignHash}/actions/send`,
  },
  messages: {
    emptyContent: 'Content cannot be empty',
    registerSuccessfull: 'Contact registered successfully!',
  },
};

export const IN_GAME_EGOI = {
  READY_TO_PLAY: 1,
  PLAYING: 2,
  FINNISH_PLAYING: 3,
  SHARED: 4,
};

export const EGOI_EXTRA_FIELDS = {
  POINTS: 1,
  PRIZE: 2,
  LIVES: 3,
  IN_GAME: 4,
};

export const DEFAULT_CONTACT_EXTRAS = {
  POINTS: '0',
  LIVES: '3',
  PRIZE: '',
  IN_GAME: IN_GAME_EGOI.READY_TO_PLAY,
};

export const EMAIL_TYPE = {
  REGISTER_EMAIL: 'REGISTER_EMAIL',
  WIN_EMAIL: 'WIN_EMAIL',
  LOSE_EMAIL: 'LOSE_EMAIL',
  SHARE_EMAIL: 'SHARE_EMAIL',
};

export const getCampaignParams = (emailType) => {
  switch (emailType) {
    case EMAIL_TYPE.WIN_EMAIL:
      return {
        TEMPLATE_ID: 17,
        SENDER_ID: 2,
        SUBJECT: 'Parabéns. Acabaste de ganhar um prémio.',
        REPLY_TO: 2,
      };
    case EMAIL_TYPE.LOSE_EMAIL:
      return {
        TEMPLATE_ID: 18,
        SENDER_ID: 2,
        SUBJECT: 'Obrigado por jogares o VIT-MAN',
        REPLY_TO: 2,
      };
    case EMAIL_TYPE.SHARE_EMAIL:
      return {
        TEMPLATE_ID: 19,
        SENDER_ID: 2,
        SUBJECT: 'Um amigo teu desafiou-te',
        REPLY_TO: 2,
      };

    default:
      return null;
  }
};

export const canPlay = (inGame, contactLives) => {
  if (inGame !== IN_GAME_EGOI.FINNISH_PLAYING && contactLives > 0) {
    return true;
  }

  return false;
};

export const getContactExtrasPayload = (points, prize, lives, inGame) => {
  const pointsObj = {
    field_id: EGOI_EXTRA_FIELDS.POINTS,
    format: 'number',
    value: points,
  };

  const prizeObj = {
    field_id: EGOI_EXTRA_FIELDS.PRIZE,
    format: 'text',
    value: prize,
  };

  const livesObj = {
    field_id: EGOI_EXTRA_FIELDS.LIVES,
    format: 'number',
    value: lives,
  };

  const inGameObj = {
    field_id: EGOI_EXTRA_FIELDS.IN_GAME,
    format: 'options',
    value: [inGame],
  };

  const extra = [];

  if (points || points === 0) {
    extra.push(pointsObj);
  }
  if (prize || prize === '') {
    extra.push(prizeObj);
  }
  if (lives || lives === 0) {
    extra.push(livesObj);
  }
  if (inGame) {
    extra.push(inGameObj);
  }

  return {
    extra,
  };
};

export const getContactRegisterPayload = (firstName, lastName, email) => ({
  base: {
    status: 'active',
    first_name: firstName,
    last_name: lastName,
    email: email,
  },
});

export const getCreatCampaignPayload = (
  listId,
  internalName,
  templateId,
  senderId,
  subject,
  replyTo
) => {
  return {
    list_id: listId,
    internal_name: internalName,
    content: {
      type: 'template',
      template_id: templateId,
    },
    sender_id: senderId,
    subject: subject,
    reply_to: replyTo,
  };
};

export const getSendEmailPayload = (email) => ({
  list_id: LIST_NUMBER,
  segments: {
    type: 'contact',
    data: email,
  },
});

const EgoiApi = () => {
  return {
    searchContactByEmail: (email) =>
      fetch(
        `${egoiApiOptions.server}${egoiApiOptions.paths.searchContact}?type=email&contact=${email}`,
        {
          method: 'get',
          headers: { ApiKey: egoiApiOptions.apiKey },
        }
      ),
    getContactById: (id) =>
      fetch(`${egoiApiOptions.server}${egoiApiOptions.paths.getContact}${id}`, {
        method: 'get',
        headers: { ApiKey: egoiApiOptions.apiKey },
      }),
    updateContact: (id, points, prize, lives, inGame) => {
      return fetch(`${egoiApiOptions.server}${egoiApiOptions.paths.getContact}${id}`, {
        method: 'PATCH',
        body: JSON.stringify(getContactExtrasPayload(points, prize, lives, inGame)),
        headers: { ApiKey: egoiApiOptions.apiKey, 'Content-Type': 'application/json' },
      });
    },
    registerContact: (firstName, lastName, email) => {
      return fetch(`${egoiApiOptions.server}${egoiApiOptions.paths.register}`, {
        method: 'post',
        body: JSON.stringify(getContactRegisterPayload(firstName, lastName, email)),
        headers: { ApiKey: egoiApiOptions.apiKey, 'Content-Type': 'application/json' },
      });
    },
    createCampaign: (listId, internalName, templateId, senderId, subject, replyTo) => {
      return fetch(`${egoiApiOptions.server}${egoiApiOptions.paths.createCampaign}`, {
        method: 'post',
        body: JSON.stringify(
          getCreatCampaignPayload(listId, internalName, templateId, senderId, subject, replyTo)
        ),
        headers: { ApiKey: egoiApiOptions.apiKey, 'Content-Type': 'application/json' },
      });
    },
    sendEmail: (campaignHash, email) => {
      return fetch(`${egoiApiOptions.server}${egoiApiOptions.paths.sendEmail(campaignHash)}`, {
        method: 'post',
        body: JSON.stringify(getSendEmailPayload(email)),
        headers: { ApiKey: egoiApiOptions.apiKey, 'Content-Type': 'application/json' },
      });
    },
  };
};

export default EgoiApi;
